import { useEffect } from 'react';
import './CursorAnimation.css'

const CursorAnimation = () => {
  useEffect(() => {
    let prevX = 0;
    let prevY = 0;

    const bganimation = (e) => {
      if ((e.clientX - prevX) * (e.clientX - prevX) + (e.clientY - prevY) * (e.clientY - prevY) > 1000) {
        let dots = document.createElement('div');
        dots.className = 'dots';
        dots.style.top = e.clientY + 'px';
        dots.style.left = e.clientX + 'px';
        prevX = e.clientX;
        prevY = e.clientY;

        for (let i = 1; i <= 15; i++) {
          for (let j = 1; j <= 15; j++) {
            let dot = document.createElement('div');
            dot.className = 'dot';
            dots.appendChild(dot);
            setTimeout(() => {
              dot.remove();
            }, 300);
            dot.style.opacity = Math.exp(-((i - 7) * (i - 7) + (j - 7) * (j - 7))) + 0.1;
            const gridSize = 15;
            const center = (gridSize + 1) / 2;
            const distance = Math.sqrt(Math.pow(i - center, 2) + Math.pow(j - center, 2));
            const maxSize = 10;
            const minSize = 2;
            const size = maxSize - distance;
            dot.style.width = `${Math.max(minSize, size)-1}px`;
            dot.style.height = `${Math.max(minSize, size)-1}px`;
          }
        }

        dots.style.transition = 'all 2s ease';

        const App = document.querySelector('.App');
        App.appendChild(dots);
      }
    };

    document.addEventListener('mousemove', bganimation);
    return () => {
      document.removeEventListener('mousemove', bganimation);
    };
  }, []);

  return null;
};

export default CursorAnimation;