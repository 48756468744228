import React, { useState, useEffect } from 'react';
import './styles.css';

const ScrollingText = () => {
  return (
    <div className="scrolling-text-container">
      <div className="scrolling-text">
        EVENTS
      </div>
    </div>
  );
};

export default ScrollingText;
