import React, { useState } from 'react';
import "./Viewers.css"
import leftHand from "../../Login/images/Left_hand.png"
import rightHand from "../../Login/images/Right_hand.png"
import plus from "../../Login/images/plus.png"
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'


const ViewersForm = () => {
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      contact: "",
      // password: "",
      college: "",
      image: "",
      // state: "",
      // city: "",
      // age: "",
      // confirm_password: "",
    });

    const [selectedImage, setSelectedImage] = useState(null); // New state for selected image

    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
  
    const handleImageChange = (event) => {
      const imageFile = event.target.files[0];
      // upload image to backend and get the link
      setSelectedImage(imageFile);
    };
  
    // const getImageLink = async () => {
    //   const response = await axios.post(
    //     "/api/users/upload",
    //     formDataWithImage,
    //     config
    //   );
    //   setFormData(
    //     {
    //       ...formData,
    //       image : response.data
    //     }
    //   )
    // }

    const handleSubmit = async (event) => {
      event.preventDefault();
  
      // 1. FormData should be updated except for the image field 
      // 2. Append image text to formData object
      // 3. Dispatch the data to backend 
  
      // if (formData.password !== formData.confirm_password) {
      //   alert("Entered Password Should be same");
      //   return;
      // }
  
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data", // Use multipart/form-data for file upload
          },
        };
  
        const formDataWithImage = new FormData();
  
        formDataWithImage.append("image", selectedImage); // Append the selected image to the form data
        // console.log(formDataWithImage)
        const urlEncoded = new URLSearchParams(formDataWithImage).toString();
        handleSubmit()
        const reply = await axios.post(
          "http://localhost:4000/pass/viewer/register",
          formData,
        );
        // localStorage.setItem("userInfo", JSON.stringify(response.data));
        // navigate("/profile");
      } catch (error) {
        alert(error.message);
      }
    };
    return (
      <div className="parent">
        <img src={leftHand} className="hover fixed left-0 top-0 h-[60vh] " />
        <img
          src={rightHand}
          className="hover fixed -right-[50px] bottom-0 h-[60vh] "
        />
        <img src={plus} className="fixed my-auto left-2" />
        <img src={plus} className="fixed my-auto right-2" />
        <div className="signup-form ml-5 mr-5 mb-10 lg:mt-24 lg:mb-10">
          <p className="heading text-4xl">Viewers Pass</p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <br />
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <br />
            <input
              type="text"
              name="contact"
              placeholder="Contact"
              value={formData.contact}
              onChange={handleChange}
              required
            />
            <br />
            <input
              type="text"
              name="college"
              placeholder="College Name"
              value={formData.country}
              onChange={handleChange}
              required
            />
            <br />
            <input
              type="file"
              name="file"
              placeholder="Upload Image (.png please)"
              accept="image/*"
              onChange={handleImageChange}
              required
            />{" "}
            {/* Image input field */}
            <button className="signup transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500">
              Submit
            </button>
            <br />
            {/* <Link to="/login">
            <button className="login bg-transparent">
              Log into Existing Account
            </button>
          </Link> */}
          </form>
        </div>
      </div>
    );

  // const [formData, setFormData] = useState({
  //   name: '',
  //   email: '',
  //   contact: '',

  //   // password: '',
  //   // country: '',
  //   // state: '',
  //   // city: '',
  //   // age: '',
  //   // confirm_password: ''
  // });

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };

  // // const register = async () => {
  // //   try{
  // //       const config = {
  // //           'Content-Type': "application/json",
  // //       }
  // //       // const user_credentials = {name,email,contact,password,age,state,city,country}
  // //       const {data} = await axios.post('/api/users/register',formData,config)
  // //       localStorage.setItem("userInfo",JSON.stringify(data))
  // //       // alert('Yayy !! You made it into the database')
  // //   }
  // //   catch(error){
  // //       alert(error)
  // //   }
  // // }

  // // const verifyCredentials = () => {

  // // }
  // const navigate = useNavigate()

  // // const handleSubmit = (event) => {
  // //   event.preventDefault()
  // //   // console.log(formData)
  // //   if(formData.password !== formData.confirm_password){
  // //     alert('Entered Password Should be same')
  // //   }
  // //   else{
  // //     register()
  // //     navigate('/profile')
  // //   }
  // // }


  // return (

  //   <div className='parent'>
  //     <img src={leftHand} className='hover fixed left-0 top-0 h-[60vh] '/>
  //       <img src={rightHand} className='hover fixed -right-[50px] bottom-0 h-[60vh] '/>
  //       <img src={plus} className='fixed my-auto left-2'/>
  //       <img src={plus} className='fixed my-auto right-2'/>
  //       <div className="signup-form ml-5 mr-5 mb-10 lg:mt-24 lg:mb-10">
  //       <p className='heading text-4xl'>Create an account</p>

  //       <form>
  //           <input type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} required/><br />
  //           <input type="text" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required /><br />
  //           <input type="text" name="contact" placeholder="Contact" value={formData.contact} onChange={handleChange} required /><br />
  //           <input type="number" name="age" placeholder="Age" value={formData.age} onChange={handleChange} required /><br />
  //           <input type="text" name="country" placeholder="Country" value={formData.country} onChange={handleChange} required /><br />
  //           <input type="text" name="state" placeholder="State" value={formData.state} onChange={handleChange} required /><br />
  //           <input type="text" name="city" placeholder="City" value={formData.city} onChange={handleChange} required /><br />
  //           <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required /><br />
  //           <input type="password" name="confirm_password" placeholder="Confirm Password" value={formData.confirm_password} onChange={handleChange} required /><br />
  //           <button className='signup transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500'>Starting Soon</button><br />
  //           <Link to="/login"><button className='login bg-transparent'>Log into Existing Account</button></Link>
  //       </form>
  //       </div>
  //   </div>

  // );
}

export default ViewersForm;