import React from "react";
import ReactDOM from "react-dom/client";
import GhmLanding from "./components/GhmLanding.js";
import Navbar from "./components/Navbar.js";
import "./GhmLanding.css";

function GhmMain() {
  return (
    <div className="ghm-container">
      <Navbar />
      <GhmLanding />
      {/* <Confirm /> */}
    </div>
  );
}

export default GhmMain;
