import number01 from '../../assets/number01.png';
import number02 from '../../assets/number02.png';
import number03 from '../../assets/number03.png';
import number04 from '../../assets/number04.png';
import number05 from '../../assets/number05.png';
import number06 from '../../assets/number06.png';
import number07 from '../../assets/number07.png';
import event1 from '../../assets/event1.png';
import event2 from '../../assets/event2.png';
import event3 from '../../assets/event3.png';
import event4 from '../../assets/event4.png';
import event5 from '../../assets/event5.png';
import event6 from '../../assets/event6.png';
import eventm1 from '../../assets/eventm1.png';
import eventm2 from '../../assets/eventm2.png';
import eventm3 from '../../assets/eventm3.png';
import eventm4 from '../../assets/eventm4.png';
import eventm5 from '../../assets/eventm5.png';
import eventm6 from '../../assets/eventm6.png';
import numberb1 from '../../assets/numberb1.png';
import numberb2 from '../../assets/numberb2.png';
import numberb3 from '../../assets/numberb3.png';
import numberb4 from '../../assets/numberb4.png';
import numberb5 from '../../assets/numberb5.png';
import numberb6 from '../../assets/numberb6.png';
import numberb7 from '../../assets/numberb7.png';

const events = [
  {
    id: 1,
    eventname: 'competitions',
    serial: number01,
    serialb: numberb1,
    title: 'Competitions',
    subheadings: ['//Robotics', '//TechOlympics', , '//Corporate' ,'//TechExpo', '//Funniche', '//Technothlon'],
    image: event1,
    imagem: eventm1,
  },
  {
    id: 2,
    eventname: 'workshops',
    serial: number02,
    serialb: numberb2,
    title: 'Workshops',
    subheadings: ['//Blockchain', '//Full Stack' , '//Prompt Engineering' , '//AI ML' , '//Cyber security', '//AWS Container Services'],
    image: event2,
    imagem: eventm2 ,
  },
  {
    id: 3,
    eventname: 'exhibitions',
    serial: number03,
    serialb: numberb3,
    title: 'Exhibitions',
    subheadings: ['//Exhibitions', '//Autoexpo and Roadshow'],
    image: event3,
    imagem: eventm3 ,
  },
  {
    id: 4,
    eventname: 'lectureseries',
    serial: number04,
    serialb: numberb4,
    title: 'Lecture-Series',
    subheadings: ['//Panel Discussions', '//Past Panels', '//Event Details'],
    image: event4,
    imagem: eventm4,
  },
  {
    id: 5,
    eventname: 'nexus',
    serial: number05,
    serialb: numberb5,
    title: 'Nexus',
    subheadings: ['//Past Panels', '//Schedule' , '//Event Details'],
    image: event5,
    imagem: eventm5,
  },
  {
    id: 6,
    eventname: 'initiatives',
    serial: number06,
    serialb: numberb6,
    title: 'Initiatives',
    subheadings: ['//Wake Up And Vote', '//Pragati', '//GHM', '//Muskaan'],
    image: event6,
    imagem: eventm6,
  },
  // {
  //   id: 7,
  //   ventname: 'technichechapters',
  //   serial: number07,
  //   serialb: numberb7,
  //   title: 'Techniche Chapters',
  //   subheadings: [],
  //   // image: event7,
  //   // imagem: eventm7,
  // },
];

export default events;

  