import { useEffect, useRef, useState } from "react";
import "../GhmLanding.css";
import { useAnimate } from "framer-motion";
import VideoPlayer from "./VideoPlayer.js";
import Ribbon1 from "../GhmAssets/ribbon-1.png";
import Ribbon2 from "../GhmAssets/ribbon-2.png";
import Ribbon3 from "../GhmAssets/ribbon-3.png";
import Ribbon4 from "../GhmAssets/gallery-ribbon.png";
import Ribbon5 from "../GhmAssets/ribbon-5.png";
import Ribbon6 from "../GhmAssets/ribbon-6.png";
import Ribbon7 from "../GhmAssets/ribbon-7.png";
import Ribbon8 from "../GhmAssets/ribbon-8.png";
import Ribbon9 from "../GhmAssets/ribbon-9.png";
import GhmLogo from "../GhmAssets/ghm-logo.png";
import GhmTitle from "../GhmAssets/ghm-title.png";
import FigLeft from "../GhmAssets/fig-left.png";
import FigRight from "../GhmAssets/fig-right.png";
import FigRightPhone from "../GhmAssets/image 382.png";
import Aftermovie from "../GhmAssets/forest.mp4";
import image1 from "../GhmAssets/image 61.png";
import image2 from "../GhmAssets/image 62.png";
import image3 from "../GhmAssets/image 63.png";
import image4 from "../GhmAssets/image 64.png";
import image5 from "../GhmAssets/image 65.png";
import image6 from "../GhmAssets/image 66.png";
import img1 from "../GhmAssets/vertical1.png";
import img2 from "../GhmAssets/vertical2.png";
import img3 from "../GhmAssets/vertical3.png";
import img4 from "../GhmAssets/vertical4.png";
import img5 from "../GhmAssets/vertical5.png";
import img6 from "../GhmAssets/vertical6.png";
import facebooklogo from "../GhmAssets/Group 783.png";
import linkedinlogo from "../GhmAssets/Group 785.png";
import twitterlogo from "../GhmAssets/Group 786.png";
import youtubelogo from "../GhmAssets/Group 788.png";
import instalogo from "../GhmAssets/Group 35057.png";
import hehe from "../GhmAssets/image 376.png";
import hehe1 from "../GhmAssets/Group 811.png";
import hehe2 from "../GhmAssets/image 380.png";
import hehe3 from "../GhmAssets/image 377.png";
import hehe4 from "../GhmAssets/image 375.png";
import hehe5 from "../GhmAssets/image 381.png";
import nhpc from "../GhmAssets/NHPClogo.png";
import ann from "../GhmAssets/Ap LOGO.png";
import medal from "../GhmAssets/Medal.png";
import healthcare from "../GhmAssets/Healthcare.png";
import refreshments from "../GhmAssets/Group 887.png";
import certificate from "../GhmAssets/Group 861.png";
import m10 from "../GhmAssets/Group 35055.png";
import Galleryimg from "../GhmAssets/Gallery.png";
import merch from "../GhmAssets/Group 35073.png";
import priceillu from "../GhmAssets/uiuiui.png";
import lifestyle from "../GhmAssets/lifestyle.png";
import beverage from "../GhmAssets/beverage.png";
import fitness from "../GhmAssets/fitness.png";
import s1 from "../GhmAssets/s1.png";
import driven from "../GhmAssets/driven.png";
import healthyhabits from "../GhmAssets/healthyhabits.png";
import hygiene from "../GhmAssets/hygiene.png";
import snacking from "../GhmAssets/snacking.png";
import refreshment from "../GhmAssets/refreshment.png";
import s2 from "../GhmAssets/s2.png";
import s3 from "../GhmAssets/s3.png";
import s4 from "../GhmAssets/s4.jpg";
import presenting from "../GhmAssets/presenting.png";
import sports from "../GhmAssets/sports.png";
import hospital1 from "../GhmAssets/hospital1.png";
import hospital2 from "../GhmAssets/hospital2.png";
import healthysnacking from "../GhmAssets/healthysnacking.png";
import { Link } from "react-router-dom";

const GhmLanding = () => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;

    if (!container || !content) return;

    let scrollSpeed = 10;
    let scrollPosition = 0;
    let isScrolling = true;

    function scrollContent() {
      if (isScrolling) {
        scrollPosition += scrollSpeed;
        container.scrollLeft = scrollPosition;
        if (scrollPosition >= content.offsetWidth - container.offsetWidth) {
          scrollPosition = 0;
        }
      }
    }

    const scrollInterval = setInterval(scrollContent, 30);

    container.addEventListener("mouseenter", () => {
      isScrolling = false;
    });

    container.addEventListener("mouseleave", () => {
      isScrolling = true;
    });

    return () => {
      clearInterval(scrollInterval);
    };
  }, []);
  const [scope, animate] = useAnimate();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const document = window.document;
    const vid = document.querySelector(".vid");
    vid.crossOrigin = "Anonymous";
    vid.loop = true;
    vid.muted = true;
    vid.type = "video/mp4" / vid.play();
    vid.autoplay = true;
  }, []);

  const handleClick = () => {
    setOpen(true);
    animate([
      [".ghm-content", { opacity: 0, zIndex: -1 }, { duration: 2 }],
      [".black", { opacity: 0 }, { duration: 1.5, at: "<" }],
      // [".r-3 img", { opacity: 0 }, { duration: 1.5, at: "<" }],
      // [".video", { zIndex: 1000 }, { duration: 1, at: "<" }],
    ]);
  };

  const handleExit = () => {
    animate([
      [".ghm-content", { opacity: 1, zIndex: 4 }, { duration: 2 }],
      [".black", { opacity: 1 }, { duration: 1.5, at: "<" }],
      // [".r-3 img", { opacity: 1 }, { duration: 1.5, at: "<" }],
    ]);
    // useEffect(() => {
    //   setTimeout(() => {
    //     const controls = window.document.querySelector(".controls-wrapper");
    //     controls.style.zIndex = -1;
    //   }, 1500);
    // }, [])
    setOpen(!open);
  };
  useEffect(() => {
    const ribbon1 = document.querySelector(".r-1");
    const ribbon2 = document.querySelector(".r-2");
    const ribbon3 = document.querySelector(".r-3");
    // const exit = document.querySelector(".exit-video")
    const gone = document.querySelector(".play");
    // console.log(ribbon.style);
    gone.addEventListener("click", () => {
      ribbon1.style.display = "none";
      ribbon2.style.display = "none";
      ribbon3.style.display = "none";
    });
    // exit.addEventListener("click",()=>{
    //   ribbon1.style.display = "block";
    //   ribbon2.style.display = "block";
    //   ribbon3.style.display = "block";
    // })
    if (open) {
      const exit = document.querySelector(".exit-video");
      exit.addEventListener("click", () => {
        ribbon1.style.display = "block";
        ribbon2.style.display = "block";
        ribbon3.style.display = "block";
      });
    }
  }, []);
  // useEffect(() => {
  //   startCarousel(); // Call the startCarousel function when the component mounts
  // }, []);

  // const url = { Aftermovie };
  const sponsors=[
    {
      logo:lifestyle,
      title:'Lifestyle Partner'
    },
    {
      logo:beverage,
      title:'Beverage Partner'
    },
    {
      logo:fitness,
      title:'Fitness Partner'
    },
    {
      logo:s1,
      title:'Travel Partner'
    },
    {
      logo:driven,
      title:'Driven By Partner'
    },
    {
      logo:healthyhabits,
      title:'Healthy Habits Partner'
    },
    {
      logo:hygiene,
      title:'Hygiene Partner'
    },
    {
      logo:snacking,
      title:'Snacking Partner'
    },
    {
      logo:refreshment,
      title:'Refreshment Partner'
    },
    {
      logo:s2,
      title:'Sponsor'
    },
    {
      logo:healthysnacking,
      title:'Healthy Snacking Partner'
    },
    {
      logo:s3,
      title:'Sponsor'
    },
    {
      logo:s4,
      title:'Sponsor'
    },
    {
      logo:sports,
      title:'Sports Partner'
    },
    {
      logo:hospital1,
      title:'Hospital Partner'
    },
    {
      logo:hospital2,
      title:'Hospital Partner'
    },
  ]
  return (
    <div className="main_ghm">
      <div className="ribbon r-1">
        <img src={Ribbon1} alt="" />
      </div>
      <div className="ribbon r-2">
        <img src={Ribbon2} alt="" />
      </div>
      <div className="ribbon r-3">
        <img src={Ribbon3} alt="" />
      </div>
      <div className="ribbon r-4">
        <img src={Ribbon4} alt="" />
      </div>
      <div className="ribbon r-5">
        <img src={Ribbon5} alt="" />
      </div>
      <div className="ribbon r-6">
        <img src={Ribbon6} alt="" />
      </div>
      <div className="ribbon r-7">
        <img src={Ribbon7} alt="" />
      </div>
      <div className="ribbon r-8">
        <img src={Ribbon8} alt="" />
      </div>
      <div className="ribbon r-9">
        <img src={Ribbon9} alt="" />
      </div>
      <main ref={scope}>
        <VideoPlayer
          src={Aftermovie}
          handleExit={handleExit}
          open={open}
          setOpen={setOpen}
        />
        <div className="ghm-content">
          <div className="ghm-logo">
            <img src={GhmLogo} alt="ghm logo" />
          </div>
          <div className="ghm-title">
            <img src={GhmTitle} alt="ghm title" />
          </div>
          <h3>
            <i>APRIL 7TH 2024</i>
          </h3>
          <div className="animated-button">
            <Link to="/ghm/register">
              <div className="btn-overflow">
                <p className="pp1">READY</p>
                <p className="pp2">SET</p>
                <p className="pp3">GO!!</p>
              </div>
            </Link>
          </div>
          <p className="play" onClick={handleClick}>
            Experience GHM 2023 ▶
          </p>
        </div>
      </main>

      <div className="tag-section">
        <div className="runners run-left">
          <img src={FigLeft} alt="" />
        </div>
        <div className="tagline-container">
          <h1 className="tag-normal">RUN</h1>
          <h1 className="tag-normal">FOR A</h1>
          <h1 className="tag-italic">CLEANER</h1>
          <h1 className="tag-italic">TOMORROW</h1>
          <div className="tagline-description">
            <h4>
              The Guwahati Half Marathon 2024 Seeks To Highlight The Urgent Need
              To Address The Issue Of Cleanliness And Sanitation Of Guwahati.
            </h4>
          </div>
        </div>
        <div className="runners run-right" id="fig-right">
          <img src={FigRight} alt="" />
        </div>
      </div>
      <div className="verticals">
        <div className="taglinevertical">
          <p>THIS YEAR WE WILL,</p>
        </div>
        <div className="horizontal-section" ref={containerRef}>
          <div className="horizontal-container">
            <img src={img1} className="image" alt="Image 1" id="imgg1"/>
          </div>
          <div className="horizontal-container">
            <img src={img2} className="image" alt="Image 2" id="imgg2" />
          </div>
          <div className="horizontal-container">
            <img src={img3} className="image" alt="Image 3" id="imgg3" />
          </div>
          <div className="horizontal-container">
            <img src={img4} className="imagee" alt="Image 4" id="imgg4" />
          </div>
          <div className="horizontal-container">
            <img src={img5} className="imagee" alt="Image 5" id="imgg5" />
          </div>
          <div className="horizontal-container">
            <img src={img6} className="imagee" alt="Image 6" id="imgg6" />
          </div>
        </div>
      </div>

      <div className="gallerymain">
        <div className="gallerytext">
          <p className="letsrun" style={{ color: "#0C0E3D" }}>
            LET'S <i>RUN</i>
          </p>
          <p className="itback">IT BACK</p>
        </div>
        <div className="gallerypics">
          <div className="gallerypics-slide">
            {/* <img src={image1} className='ghmgallerypics-1'></img>
             <img src={image4} className='ghmgallerypics-2'></img>
             <img src={image5} className='ghmgallerypics-3'></img>
             <img src={image2} className='ghmgallerypics-4'></img>
             <img src={image3} className='ghmgallerypics-5'></img>
             <img src={image6} className='ghmgallerypics-6'></img>
             <img src={image1} className='ghmgallerypics-1'></img> */}
            <img src={Galleryimg} className="ghmgallerypics-1"></img>
            <img src={Galleryimg} className="ghmgallerypics-1"></img>
          </div>
        </div>
        <div className="lowertext">
          <p className="lowertxt">Experience Guwahati Half marathon</p>
          <p className="lowertxt">with our runners from last year.</p>
        </div>
      </div>

      <div className="companiessponsors">
        <div className="sponsor-heading">
          <div className="sponsor-heading-tag">
            GOING THE
            <br />
            EXTRA <i>MILE</i>
          </div>
          <div className="sponsor-heading-subtag">Runner Benefits</div>
        </div>
        <div className="benifits-row">
          <div className="benifits-icon">
            <img src={medal} className="benefitsghm"></img>
            <div className="icon-text">Medal</div>
          </div>
          <div className="benifits-icon1">
            <img src={m10} className="benefitsghm-bib"></img>
            <div className="icon-text">RFID Timed Bib</div>
          </div>
          <div className="benifits-icon">
            <img src={healthcare} className="benefitsghm"></img>
            <div className="icon-text">
              Healthcare
              <br />
              support
            </div>
          </div>
          <div className="benifits-icon1">
            <img src={refreshments} className="benefitsghm"></img>
            <div className="icon-text">Refreshments</div>
          </div>
          <div className="benifits-icon">
            <img src={certificate} className="benefitsghm-certificate"></img>
            <div className="icon-text">
              Participation
              <br />
              Certificate
            </div>
          </div>
        </div>
      </div>

      <div className="ghmmerch-container">
        <div className="ghmmerch-paint-container">
          <div className="merchimage">
            <img src={merch} className="merchdesignimg"></img>
          </div>
          <div className="merchprice">
            <div className="regularprice">
              ₹399/- <div className="pricetext">for Regular Customers </div>
            </div>
            <div className="gloryprice">
              ₹349/-
              <div className="pricetext-glory">for Glory Runners (21kms)</div>
            </div>
          </div>
          <div className="buynowbutton">
            <div className="animated-button-merch">
              <p className="pp1-merch">
                <a href="https://forms.gle/SZ4fXsvKikPUCZeD6">BUY NOW</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="price-container">
        <div className="woman">
          <img src={priceillu} alt="" />
        </div>
        <div className="price-text">
          <div className="line-1-1">
            Run <i className="font-orange-1">6Kms </i>
          </div>
          <div className="line-1-2">
            for <i className="font-violet-1">Free</i>
          </div>
          <div className="line-2-1">
            Run <i className="font-orange-2">21Kms</i>
          </div>
          <div className="line-2-2">
            at just <i className="font-violet-1">₹520</i>
          </div>
        </div>
      </div>

      <div className="sponsorpage">
        <div className="sponsors">
          <div className="past-sponsors">SPONSORS</div>
          <div className="companies">
            <div className="sponsorname">Title Sponsor</div>
            <a className="companies-a" href="https://www.nhpcindia.com/"><img className="img" src={nhpc} alt="nhpc"></img></a>
          </div>
          <div className="companies">
            <div className="sponsorname">Powered By</div>
            <a className="companies-a1" href="https://www.annapurnagroup.in/"><img className="img" src={ann} alt="ann"></img></a>
          </div>
          <div className="companies">
            <div className="sponsorname">Presented By</div>
            <a className="companies-a1" href=""><img className="img-presenting" src={presenting} alt="ann"></img></a>
          </div>
        </div>
      </div>
      <div className="other-sponsors-wrapper">
            <div className="other-sponsors-inner-wrapper">
                {sponsors.map((item)=>{
                  return(<>
                  <div className="sponsors-item-box">
                    <div className="sponsors-title-wrapper sponsorname">{item.title}</div>
                    <div className="sponsors-img-wrapper"><img className="sponsors-img" src={item.logo}/></div>
                  </div>
                  </>)
                })}

            </div>
      </div>

      <div className="footer-ghm">
        <div className="vector">
          <a
            href="https://www.instagram.com/techniche_iitguwahati?igsh=YTh3NWRmbmpwbTRu"
            className="instalogosghm"
          >
            <img src={instalogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a
            href="https://www.facebook.com/techniche.iitguwahati/"
            className="facebooklogosghm"
          >
            <img src={facebooklogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a
            href="https://in.linkedin.com/company/techniche-iitg"
            className="linkedinlogosghm"
          >
            <img src={linkedinlogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a href="" className="twitterlogosghm">
            <img src={twitterlogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a
            href="https://www.youtube.com/user/technicheiitg"
            className="youtubelogosghm"
          >
            {" "}
            <img src={youtubelogo} style={{ width: "100%", height: "100%" }} />
          </a>
        </div>
        <div className="lowerfooter">
          <div className="leftside">
            <div className="seeyouatthe">
              <p className="startingline">
                See you at the <i>starting line</i>
              </p>
            </div>
            <div className="writetous">
              <h1 id="writeto">Write to Us</h1>
              <a href="" id="mailtechniche">
                ✉️info@technicheiitg.in
              </a>
            </div>
          </div>
          <div className="rightside">
            <div className="quicklinksblock">
              <h2 className="quicklinksheading">Quick Links</h2>
              <p className="linksghm">
                <a
                  href="https://techniche.org/ghm/register"
                  className="linkitems"
                >
                  Register Now
                </a>
              </p>
              <p className="linksghm">
                <a href="https://techniche.org/ghm/about" className="linkitems">
                  About GHM
                </a>
              </p>
              <p className="linksghm">
                <a
                  href="https://techniche.org/eventdetails"
                  className="linkitems"
                >
                  Event Details
                </a>
              </p>
            </div>
            <div className="contactusghm">
              <h2 className="quicklinksheading">Contact Us</h2>
              <p className="contact-details">Shikhar Mishra</p>
              <a className="contact-number" href="tel:+918690624922">
                +91 8690624922
              </a>
              <p className="contact-details">Divyanshu Tiwari</p>
              <a className="contact-number" href="tel:+918750791896">
                +91 8750791896
              </a>
              <p className="contact-details">Rachit Shah</p>
              <a className="contact-number" href="tel:+917389007970">
                +91 7389007970
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GhmLanding;
