import samyak from '../../../assets/samyak.png'
import harsh from '../../../assets/harsh.png'
import abhijeet from '../../../assets/abhijeet.png'
import sourdham from '../../../assets/sourdham.png'
import yash from '../../../assets/yash.png'
import susanna from '../../../assets/susanna.png'
import pranshik from '../../../assets/pranshik.png'
import pranshu from '../../../assets/pranshu.png'
import aditya from '../../../assets/aditya.png'
import sukanya from '../../../assets/sukanya.png'
import headDemo from '../../../assets/headDemo.png'
import srishti from '../../../assets/srishti.png'
import kayra from '../../../assets/kayra.png'
import aarya from '../../../assets/aarya.png'
import amogha from '../../../assets/amogha.png'

export const TeamData = [
    {name:'Samyak Sharma',team:'Convener',url:samyak, linkdn: 'https://www.linkedin.com/in/samyaksharma03/'},
    {name:'Harsh Gupta',team:'Finance Head',url:harsh, linkdn:'https://www.linkedin.com/in/harsh-gupta-a2753b228/'},
    {name:'Pranshu Singh',team:'Events Head',url:pranshu, linkdn: 'https://www.linkedin.com/in/pranshu-singh-2b768a226?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app '},
    {name:'Prashik Meshram',team:'Events Head',url:pranshik, linkdn: 'https://www.linkedin.com/in/prashik-meshram-b529311b4/ '},
    {name:'Srishti Murarka',team:'Events Head',url:srishti, linkdn: ' https://www.linkedin.com/in/srishti-murarka/'},
    {name:'Abhijeet Sharma',team:'Corporate Relations and Marketing Head',url:abhijeet, linkdn: 'https://www.linkedin.com/in/sharma-abhijit/ '},
    {name:'Soudharm Jhanjhree',team:'Corporate Relations and Marketing Head',url:sourdham, linkdn: 'https://www.linkedin.com/in/soudharm-jhanjhree-29713a22a/ '},
    {name:'Aditya Kumar',team:'Public Relations Head',url:aditya, linkdn: ' https://www.linkedin.com/in/aditya-kumar-068680230/'},
    {name:'Kayra Reying Pao',team:'Media and Branding Head',url:kayra, linkdn: ' https://www.linkedin.com/in/kayra-reying-pao-190a71202/'},
    {name:'Aarya Ghodke',team:'Creatives Head',url:aarya, linkdn: 'https://www.linkedin.com/in/aarya-ghodke-569b56247/'}, 
    {name:'Amogha Margassery',team:'Creatives Head',url:amogha, linkdn: 'https://www.linkedin.com/in/amogha-margassery/'},   
    {name:'Susanna Joseph',team:'Web Operations Head',url:susanna, linkdn: 'https://www.linkedin.com/in/susannajoseph04/'},
    {name:'Yash Kataria',team:'Web Operations Head',url:yash, linkdn: 'https://www.linkedin.com/in/yash-kataria-4839a01b3/'},
]