import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../services/authContext';
import ham from '../images/hamburger.png';


const Navbar = () => {
  const { user, logout } = useAuth(); 
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = () => {
    localStorage.setItem('formSubmitted', 'false');
    logout();
    navigate('/ca');
  };

  useEffect(() => {
    if (user && user.sanitizedHash) {
     
    }
  }, [user]);

  return (
    <>
      <nav className='canav'>
        <img className="mobilenav" src={ham} alt="menu" onClick={handleToggleMenu} />
        <ul className={showMenu ? 'show' : ''}>
          <li><Link to="/ca">Home</Link></li>
          {user && user.sanitizedHash && (
            <li><Link to={`/cadashboard/${user.sanitizedHash}/viewtasks`} onClick={handleToggleMenu}>Dashboard</Link></li>
          )}
          {user && user.token && (
            <li><Link to={`/cadashboard/${user.sanitizedHash}/leaderboard`} onClick={handleToggleMenu}>Leaderboard</Link></li>
          )}
          {user && user.sanitizedHash ? (
            <li><button onClick={handleLogout} className='navbutton'>Logout</button></li>
          ) : (
            <li><button><Link to="/calogin" onClick={() => { navigate('/ca'); }}>Login/ Register</Link></button></li>
          )}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
