import React, { useState, useEffect } from 'react';
import "./CAVerify.css"
import leftHand from "../../Login/images/Left_hand.png"
import rightHand from "../../Login/images/Right_hand.png"
import plus from "../../Login/images/plus.png"
import CAHeaderLogin from '../CA-HeaderLogin/ca-headerlogin';
import { Link} from 'react-router-dom'
import { redirect, useNavigate } from "react-router-dom";

const CAVerify = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/caverify');
  }, [navigate]);
  return (
    <div className='parent relative'>
        <CAHeaderLogin/>
        <img src={leftHand} className='hover fixed left-0 top-0 h-[60vh] '/>
        <img src={rightHand} className='hover fixed -right-[50px] bottom-0 h-[60vh] '/>
        <img src={plus} className='fixed my-auto left-2'/>
        <img src={plus} className='fixed my-auto right-2'/>


        <div className="login-form-verify mt-30 md:mt-20">
        <p className='heading text-2xl'>Your email has been successfully verified! Login to acess your dashboard</p>
        <form className="texth">
            <Link to="/calogin"><button className='loginx  bg-gradient-to-r from-blue-500 to-violet-900'>Log In</button><br /></Link>
        </form>
        </div>
    </div>
  );
}

export default CAVerify;