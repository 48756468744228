import React from "react";
import { Link } from "react-router-dom";
import link from "../../../assets/ExternalLink.png";
import './LongLearnMoreTile.css';
import { CIcon } from '@coreui/icons-react';
import { cilExternalLink } from '@coreui/icons';

const Tile = (props) => {
    const isAbsoluteLink = props.link.startsWith('http://') || props.link.startsWith('https://');
    const linkTo = isAbsoluteLink ? props.link : `/${props.link}`;
    
    return (
        <div className="LongTileWrapper">
            <div className="LongTileContainer">
                <Link to={linkTo}>
                    <img className="LongTileImage" src={props.img} alt="Blockchain" />
                    <p style={{ color: props.color }}>{props.title}</p>
                    <div className="LearnMoreWrapper" style={{ marginTop: '1vh' }}>
                        <div className="LearnMoreContainer">
                            {props.text}
                        </div>
                        <CIcon className="LearnMoreicon" icon={cilExternalLink} />
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default Tile;
