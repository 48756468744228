import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { gsap } from 'gsap';
import { motion } from "framer-motion";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ExcitingEvents from './ExcitingEvents';
//gsap.registerPlugin(ScrollTrigger);
const Journey = () => {
  const [currentFrame2, setCurrentFrame2] = useState(0);
  const [showEvents2, setShowEvents2] = useState(false);
  const [isLoading2, setIsLoading2] = useState(true);
  let frameCount2 ; // Total number of images
  let images2 = [];
  // Preload images
  if(window.innerWidth>740)
    {
      frameCount2 = 54;
     images2 = Array.from({ length: frameCount2 }, (_, index) => (
      require(`../desktop-map/Desktop map 1_000${String(index + 16)}.png`)
    ));
   }
   else{
    frameCount2 = 27;
      images2 = Array.from({ length: frameCount2 }, (_, index) => (
        require(`../phone-map/Map Zoom Mobile_000${String(index + 42)}.png`)
      ));
   }
  const preloadImages = (imageArray) => {
    return Promise.all(imageArray.map(src => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    }));
  };
  useEffect(() => {
    preloadImages(images2).then(() => {
     // console.log('All images preloaded');
      setIsLoading2(false);
    }).catch((error) => {
      console.error('Error preloading images:', error);
      setIsLoading2(false);
    });

    const handleScroll2 = () => {
      let scroll2 = window.scrollY;
      let videoTrigger2 = document.getElementById('video-trigger');
    
      if (!videoTrigger2) return;
    
      let offset2 = videoTrigger2.offsetTop;
      let height2 = 0.5 * window.innerHeight;
      let videoBlock2 = document.querySelector('.location-video-block');
      let excitingEvents2 = document.querySelector('.Exciting-events');
      let journeyContent2 = document.querySelector('.journey-content');
      let textElements2 = document.querySelectorAll('.Journey-through-techniche-block'); // Select text elements
    
      // Image animation logic
      if (scroll2 > offset2 && scroll2 < offset2 + height2) {
        if (excitingEvents2) excitingEvents2.style.opacity = 0;
        if (videoBlock2) videoBlock2.style.position = "relative";
        if (journeyContent2) journeyContent2.style.position = "fixed";
    
        const maxScroll2 = window.innerHeight * 0.5; // Keep the image animation effect consistent
        const scrollFraction2 = (scroll2 - offset2) / maxScroll2;
        const newFrame2 = Math.min(
          Math.floor(scrollFraction2 * frameCount2),
          frameCount2 - 1
        );
    
        setCurrentFrame2(newFrame2);
        setShowEvents2(newFrame2 === frameCount2 - 1);
      }
    
      // Text opacity logic
      const textFadeStart = offset2+200; // Start fading effect at the start of the section
      const textFadeEnd = offset2 + height2 * 0.9; // End fading effect at a certain scroll position
    
      if (scroll2 > textFadeStart && scroll2 < textFadeEnd) {
        const textScrollFraction2 = (scroll2 - textFadeStart) / (textFadeEnd - textFadeStart);
        textElements2.forEach((textElement) => {
          textElement.style.opacity = 1 - Math.min(textScrollFraction2 * 1.5, 1); // Adjust the factor to control the fading rate
        });
      } else if (scroll2 >= textFadeEnd) {
        textElements2.forEach((textElement) => {
          textElement.style.opacity = 0;
        });
      } else {
        // Ensure text opacity is 1 if scrolling above the start
        textElements2.forEach((textElement) => {
          textElement.style.opacity = 1;
        });
      }
    
      if (scroll2 > offset2 + height2 + 0.02 * window.innerHeight) {
        if (journeyContent2) {
          journeyContent2.style.position = "absolute";
          journeyContent2.style.bottom = "0";
          journeyContent2.style.top = "";
        }
        if (excitingEvents2) excitingEvents2.style.opacity = 1;
        setShowEvents2(true);
      }
    
      if (scroll2 < offset2) {
        if (journeyContent2) {
          journeyContent2.style.position = "absolute";
          journeyContent2.style.top = "0";
          journeyContent2.style.bottom = "";
        }
        if (excitingEvents2) excitingEvents2.style.opacity = 0;
      }
    };
    
    
    
    // Adding event listener for scroll
    document.addEventListener('scroll', handleScroll2);
  
    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('scroll', handleScroll2);
    };
  }, [frameCount2, images2]);
  
  return (
    <div className='Journey' id="video-trigger">
      <div className='journey-content' id="journey-content" >
      <div className='location-video-block' style={{overflowY:"hidden"}}>
        {/* <video src={loc_video}  id="video-loc" style={{ objectFit: "fill" }}/> */}
        {!isLoading2 && (
        <motion.img
          className='location-video-imgset'
          key={currentFrame2}
          src={images2[currentFrame2]}
          alt={`Frame ${currentFrame2 + 1}`}
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.1 }}
          style={{
            width: '100vw',
            // position: 'fixed',
            top: 0,
            left: 0,
            objectFit: 'cover',
            backgroundImage: `url(${images2[0]})`,
          }}
        />)}
    
      </div>
      <div className='Journey-through-techniche-block'>
        <p className='Journey-through-techniche-heading1'>Journey Through</p>
        <p className='Journey-through-techniche-heading2' style={{ fontStyle: "italic", color: "#F3FF63" }}>Techniche</p>
        <div className='Journey-through-techniche-heading3'>
          Know all about Techniche, the largest techno-management festival in the northeast, explore captivating photos, and meet the innovative team driving it forward.
        </div>
        <div style={{ paddingTop: "8vh", height: "16vh" }}>
            <Link to="/about" style={{height: '12vh'}}>
              <div className='merch-btn-wrapper' style={{backgroundColor:'black',width:'max-content', padding:'0.05vh', display:'flex', justifyContent:'center', alignItems:'center', }}>
                  <span className='merch-btn'>Know More</span>
              </div>
            </Link >
        </div>
      </div>
     
      {showEvents2 && <ExcitingEvents />}
       
     
      </div>
    </div>
  );
};

export default Journey;