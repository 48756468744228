import React, { useState } from 'react';
import "./Login.css";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useAuth } from '../../../services/authContext';
import Header from '../../Header/header';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { loginmain } = useAuth();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(baseURL+'/user/login', formData);
      const { sanitizedHash, name, t_id, email } = response.data;
      loginmain({ name, t_id, email, sanitizedHash });
      localStorage.setItem('isLoggedInMain', true);
      navigate(`/dashboard/${sanitizedHash}`);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setErrorMessage('Please verify your email before logging in.');
      } else {
        setErrorMessage('Login failed. Please check your credentials.');
      }
    }
  };

  return (
    <div className='loginbackground'>
      <div className="bluebox">
        <p>Welcome Back</p>
        <form onSubmit={handleSubmit}> 
          <div className='input-feild-wrapper'>
            <div className='input-feild-indicator'>Email</div>
            <input type="text" name="email" placeholder="enter email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className='input-feild-wrapper'>
            <div className='input-feild-indicator'>Email</div>
            <input type="password" name="password" placeholder="enter password" value={formData.password} onChange={handleChange} required />
          </div>
           <div className="button-container">
            <button type="submit">Login</button><br />
            <button type="button" style={{backgroundColor:'#F7F8FF', color:'#2D48D5'}}><Link to="/login" style={{color:'#2D48D5', fontWeight:'300'}}>Sign Up</Link></button>
            
          </div>
        </form>
        {errorMessage && <div className="error">{errorMessage}</div>}
      </div>
    </div>
    
  );
};

export default LoginForm;