import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { redirect, useNavigate } from "react-router-dom";
import axios from 'axios'
import { useAuth } from '../../../services/authContext';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const CoreTeamLogin = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  })
  const [sanitizedHash, setSanitizedHash] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate()
  const { loginmain } = useAuth();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const response = await axios.post(baseURL+'/coreteam/login', formData);
      const { message, type, hashedPassword } = response.data;
      if(type=='robotics') navigate(`/2024coretechnicheteamcontrol/roboticsdashboard/${hashedPassword}`);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setErrorMessage('Please verify your email before logging in.');
      } else {
        setErrorMessage('Login failed. Please check your credentials.');
      }
    }
  };


  return (
    <div className='parent relative'>


        <div className="login-form mt-30 md:mt-20">
        <p className='heading text-6xl'>Welcome Back!</p>
        <form onSubmit={handleSubmit}> 
            <input type="text" name="username" placeholder="Username" value={formData.username} onChange={handleChange} required /><br />
            <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required /><br />
            {/* <a href="#" className='forgot-password mx-310'>Forgot password?</a><br /> */}
            <button className='loginx  bg-gradient-to-r from-blue-500 to-violet-900'>Login</button><br />
            <Link to="/signup"><button className='w-[60%] p-3 mt-6 rounded-full bg-transparent border-2 border-gray-400'>Not a member? Signup</button></Link>
        </form>
        </div>
    </div>
  );
}

export default  CoreTeamLogin;