import "./CAHOME.css";
import Navbar from "./nav";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import Accordion from "./accordion";
import gsap from "gsap";
import { useEffect } from "react";
import { ScrollTrigger } from "gsap/all";
import test from '../images/test.svg';
import left from '../images/left.png';
import right from '../images/right.png';
import hero from '../images/hero.png';
import video from '../images/video.png';
import videomobile from '../images/video_mobile.svg';
import contact from '../images/contact.png';
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);


const faq = [
  {
    q: "What does it mean to be a College Ambassador?",
    a: "As a College Ambassador, leverage the chance to showcase your college at Techniche, IIT Guwahati. Your role is key in connecting these two esteemed institutions and to be a part Techniche.",
  },
  {
    q: "What are the responsibilities of a College Ambassador?",
    a: "As a College Ambassador, your responsibilities will involve skill enhancement through social media management, trend analysis, event organisation, and various tasks. Additionally, you'll play a crucial role in hosting events and sessions on your campus, all under the umbrella of Techniche, IIT Guwahati.",
  },
  {
    q: "How much time commitment is required for the CA role?",
    a: "The time commitment varies, but it typically involves a few hours per week for promotion, organising events, and reporting on your progress.",
  },
  {
    q: "Can I be a Campus Ambassador if I'm not from a technical background?",
    a: "Yes, Techniche values diversity, and you don't necessarily need a technical background. Passion for promoting and conducting technical events in your college and good skills are often key criteria.",
  },
  {
    q: "How can I stay updated on Techniche events and announcements?",
    a: "Follow Techniche's official website, social media accounts, and subscribe to newsletters for the latest updates and announcements.",
  },
  {
    q: "Can CAs suggest ideas for improving events?",
    a: "We welcome feedback and suggestions from ambassadors for enhancing the event experience.It's encouraged to share constructive ideas.",
  },
  {
    q: "How will my progress be monitored?",
    a: "You will upload proofs of your work on the CA portal; we will verify them and reward you with points that measure your progress. Please feel free to contact your mentors if you have any queries.",
  }
];

function CAhome() {

  return (
    <div className="CApage">
      <Navbar />
      <section className="cahero ">
        <div className="hero-con">
          <h1 className="head1">GET A CERTIFICATE</h1>
          <h1 className="head1">
            FROM <span>IIT GUWAHATI</span>
          </h1>
          <p>
            The role of CAs is more critical than ever before, and this is an
            excellent opportunity for us all to work together and make this
            edition of Techniche a grand success.
          </p>
          <Link to='/casignuP'><button className="registerButton">REGISTER</button></Link>
        </div>
        <img src={hero} alt="" />
      </section>
      <section className="section2">
        <div className="video-con laptop">
          <img src={video} alt="" />
        </div>
        <div className="video-con mobile">
          <img src={videomobile} alt="" />
        </div>
        <div className="steps">
          <h1>
            BECOME A CA IN JUST <span>4 STEPS !</span>
          </h1>
          <ul>
  <li>
    <h3>1</h3>
    <div className="steps-li-con">
      <h2>REGISTER</h2>
      <p>
        Sign up to become a Campus Ambassador at Techniche and join our dynamic community of students from across the country.
      </p>
    </div>
  </li>
  <li>
    <h3>2</h3>
    <div className="steps-li-con">
      <h2>COMPLETE TASKS</h2>
      <p>
        Review the tasks listed on your dashboard. Follow the task instructions and upload the Google Drive link to your proof of completion.
      </p>
    </div>
  </li>
  <li>
    <h3>3</h3>
    <div className="steps-li-con">
      <h2>VERIFY TASKS</h2>
      <p>
        Our team will verify or reject your submissions. Once verified, you will earn points.  
      </p>
    </div>
  </li>
  <li>
    <h3>4</h3>
    <div className="steps-li-con">
      <h2>COLLECT REWARDS</h2>
      <p>
        As you accumulate points, you will be able to redeem rewards and incentives we have in store.
      </p>
    </div>
  </li>
</ul>
        </div>
      </section>
      <section className="carousel">
        <h1>
          WHAT OUR <span>PAST CA'S</span> HAVE TO SAY...
        </h1>
        <div className="laptop">
          <Splide
            options={{
              type: "loop",
              perPage: 3,
              perMove: 1,
            }}
          >
            <SplideSlide>
              <div className="testcon">
                <h3>Naga Bhuvan, Rank 1 2023</h3>
                <img src={test} alt="" />
                <div>
                  "The connections I made with fellow ambassadors, participants, and industry leaders were invaluable. Techniche's events and workshops showcased cutting-edge ideas and inspired all attendees to think creatively."
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="testcon">
                <h3>Ajay Thakur, Rank 2 2023</h3>
                <img src={test} alt="" />
                <div>
                  "Serving as a Techniche 2023 Campus Ambassador was transformative. It enhanced my communication, public speaking, and social media marketing skills. Most importantly, Techniche ignited my passion for innovation."
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="testcon">
                <h3>Shikhar gupta, Rank 3 2023</h3>
                <img src={test} alt="" />
                <div>
                  "Gaining new skills like adaptability and learning to adapt according to the different situations given was a great experience. I had the wonderfull opportunity to network with people from my college and IIT Guwahati."
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="testcon">
                <h3>Tessa Joseph</h3>
                <img src={test} alt="" />
                <div>
                  "My experience was filled with learning, networking, and collaborating with like-minded people. It enhanced my never-give-up attitude and taught me to balance side commitments alongside academics."
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="testcon">
                <h3>Rahul Yadav, IIT Guwahati</h3>
                <img src={test} alt="" />
                <div>
                  "Techniche IIT Guwahati offers tech enthusiasts an incredible platform to learn and gain knowledge from top professionals. Sharing this stage with like-minded youth and industry experts is a pleasure."
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
        <div className="mobile">
          <Splide
            options={{
              type: "loop",
              perPage: 1,
              perMove: 1,
            }}
          >
            <SplideSlide>
              <div className="testcon">
                <h3>Naga Bhuvan, Rank 1 2023</h3>
                <img src={test} alt="" />
                <div>
                  "The connections I made with fellow ambassadors, participants, and industry leaders were invaluable. Techniche's events and workshops showcased cutting-edge ideas and inspired all attendees to think creatively."
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="testcon">
                <h3>Ajay Thakur, Rank 2 2023</h3>
                <img src={test} alt="" />
                <div>
                  "Serving as a Techniche 2023 Campus Ambassador was transformative. It enhanced my communication, public speaking, and social media marketing skills. Most importantly, Techniche ignited my passion for innovation."
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="testcon">
                <h3>Shikhar gupta, Rank 3 2023</h3>
                <img src={test} alt="" />
                <div>
                  "Gaining new skills like adaptability and learning to adapt according to the different situations given was a great experience. I had the wonderfull opportunity to network with people from my college and IIT Guwahati."
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="testcon">
                <h3>Tessa Joseph</h3>
                <img src={test} alt="" />
                <div>
                  "My experience was filled with learning, networking, and collaborating with like-minded people. It enhanced my never-give-up attitude and taught me to balance side commitments alongside academics."
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="testcon">
                <h3>Rahul Yadav, IIT Guwahati</h3>
                <img src={test} alt="" />
                <div>
                  "Techniche IIT Guwahati offers tech enthusiasts an incredible platform to learn and gain knowledge from top professionals. Sharing this stage with like-minded youth and industry experts is truly memorable experience!."
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </section>
      <section className="faq">
        <div className="img-con">
          <img src={left} className="left-faq"></img>
          <img src={right} className="right-faq"></img>
        </div>
        <div className="mobile img-con2">
          <img src={left} className="left-faq-2"></img>
        </div>
        {faq.map((a) => (
          <Accordion a={a} />
        ))}
      </section>
      <div className="video-con">
        <img src={contact} alt="" />
      </div>
    </div>
  );
}

export default CAhome;