import YearCard from './YearCard.js'
import YearSlide from './YearSlide.js'
import { years } from './YearData.js'
import { useEffect,useRef,useState} from 'react'
import './style.css'

const YearsAbout = ()=>{
  const [current , setCurrent]=useState(0)
  const [isVisible, setIsVisible] = useState(true);
  const parentRef=useRef(null)

  const callbackYears = ()=>{
    if(isVisible && parentRef.current){
      let passed = 100*(window.scrollY-parentRef.current.offsetTop)/window.innerHeight;
      passed=passed<0?0:passed;
      if(passed<25) setCurrent(0);
      else if(passed>=25 && passed<60) setCurrent(1);
      else if(passed>=60 && passed<92) setCurrent(2);
      else if(passed>=92 && passed<=126) setCurrent(3);
      else if(passed>=126 && passed<=142) setCurrent(4);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', callbackYears);
    return () => {
        window.removeEventListener('scroll', callbackYears);
    };
}, [isVisible]);

  useEffect(() => {
    const observerYears = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            setIsVisible(entry.isIntersecting);
            if(entry.isIntersecting) {
              document.querySelector('.year-card-wrapper').classList.add('year-card-visible');
              document.querySelector('.h-infinite-carousel').style.position='sticky';
            }
            else{
              document.querySelector('.year-card-wrapper').classList.remove('year-card-visible');
              document.querySelector('.h-infinite-carousel').style.position='relative';
            } 
        });
    }, {threshold: 0.2});

    if (parentRef.current) observerYears.observe(parentRef.current);
    return () => {
        if (parentRef.current) observerYears.unobserve(parentRef.current);
    };
}, []);

  useEffect(()=>{
    const img = document.querySelector('.year-card-img')?.firstElementChild;
    img?.classList.add('fade');
    const txt = document.querySelector('.year-card-text')?.firstElementChild;
    txt?.classList.add('fade');
    const carousel = document.querySelector('.h-infinite-carousel')?.firstElementChild;
    carousel?.classList.add('fade');

    setTimeout(()=>{
      img?.classList.remove('fade')
      txt?.classList.remove('fade')
      carousel?.classList.remove('fade')
    },2000)
  },[current])

  return (
  <div className='years-parent-wrapper'>
    <div className='years-first-slide' style={{color:'white',display:'flex',justifyContent:'center',alignItems:'center'}}>
      <div className='years-heading'>Techniche <span style={{fontStyle:'italic',color:'#F3FF63'}}>Over the Years</span></div>
    </div>
    <div ref={parentRef} className='years-outer-wrapper'>
      <div className='h-infinite-carousel' style={{position:'sticky'}}>
          {current>-1 &&
          <div className='inner-carousel'>
            <YearSlide year={years[current]} color='white'/>
          </div>
          }
      </div>
      <div className='years-inner-wrapper' 
      style={{background:'linear-gradient(to bottom ,rgb(187, 187, 187) 0%,rgb(187, 187, 187) 100%,rgb(202, 202, 202) 100%,rgb(202, 202, 202) 100%)'}}>
        <div className='slider' style={{transition:'0.5s',height:'auto'}}>
          {years.map((year,index)=>{
            if(index>0) return(
                <div className='slider-inner' id={`slider-${index}`} key={index} style={{overflowX:'hidden'}}>
                <YearSlide year={year} color=' #2d48d5' />   
                </div> 
              )  
            })}
        </div>
      </div>
      {current>-1 &&<YearCard years={years} current={current}/>} 
    </div>
    </div>)
  }
  
  export default YearsAbout