import React, { useEffect, useState } from "react";
import "./CAMap.css";
import axios from 'axios'
import CAHeaderLogin from "../../CA-HeaderLogin/ca-headerlogin";

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const CAFetchMap = () => {

    const [tasksMap, setTasksMap] = useState([]);

    useEffect(() => {
        fetchMap();
    }, []);

    const fetchMap = async () => {
        try {
            const response = await axios.get(baseURL + '/catasksupload/retrieveMapping');
            setTasksMap(response.data);
            //console.log(tasksMap.completion);
        } catch (error) {
            console.log(error);
        }
    };

    const verifySubmittedTask = async (task_id, user_id) => {
        try {
            await axios.put(baseURL + '/catasksupload/verifiedTask', { task_id, user_id });
            const updatedTasksMap = tasksMap.map(taskMap => {
                if (taskMap.task_id === task_id && taskMap.user_id === user_id) {
                    return { ...taskMap, isVerified: 2 };
                }
                return taskMap;
            });
            setTasksMap(updatedTasksMap);
            alert("Task Verified");
        } catch (error) {
            console.log(error);
        }
    }

    const rejectSubmittedTask = async (task_id, user_id) => {
        try {
            await axios.put(baseURL + '/catasksupload/rejectedTask', { task_id, user_id });
            const updatedTasksMap = tasksMap.map(taskMap => {
                if (taskMap.task_id === task_id && taskMap.user_id === user_id) {
                    return { ...taskMap, isVerified: 1 };
                }
                return taskMap;
            });
            setTasksMap(updatedTasksMap);
            alert("Task Rejected");
        } catch (error) {
            console.log(error);
        }
    }


    return (

        <div>
            <div className="message-from-wo">
                <p><b>Message from Web Operations:</b> We didn't make a phone view for this page. Use your laptops for the admin panel.</p>
                <p><b>Message from Web Operations:</b> Refrain from sharing the link to this page outside Public Relations Core Team Techniche</p>
            </div>
            <div className="main1">
                <div>
                    <div className="table1">
                        <table>
                            <thead>
                                <tr>
                                    <th className="cell-font">UserId</th>
                                    <th className="cell-font">TaskId</th>
                                    <th className="cell-font">Task Link</th>
                                    <th className="cell-font">Verified</th>
                                    <th className="cell-font">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasksMap.map((taskMap, index) => (
                                    <tr key={index}>
                                        <td className="cell-font">{taskMap.user_id}</td>
                                        <td className="cell-font">{taskMap.task_id}</td>
                                        <td className="cell-font"><a href={`http://${taskMap.Link}`} target="_blank" rel="noopener noreferrer">{taskMap.Link}</a></td>
                                        <td className="cell-font">
                                            {taskMap.isVerified === 0 ? "Pending" :
                                                taskMap.isVerified === 1 ? "Rejected" :
                                                    taskMap.isVerified === 2 ? "Accepted" :
                                                        "Unknown"}
                                        </td>
                                        <td className="cell-font">
                                            <button onClick={() => verifySubmittedTask(taskMap.task_id, taskMap.user_id)}>Verify Task</button>
                                            <button onClick={() => rejectSubmittedTask(taskMap.task_id, taskMap.user_id)}>Reject Task</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CAFetchMap;

