const prizes = [
    {position:'Winner' , prize:'Rs.25,000/-'},
    {position:'1st Runner Up' , prize:'Rs.15,000/-'},
    {position:'2nd Runner Up' , prize:'Rs.10,000/-'}
]
const contacts = [
    {name:'Veenas Jaiswal',phone:'+91 9120410232'},
    {name:'Kuldeep Patidar',phone:'+91 9669616144'},
    {name:'',phone:''},
]
const schedule  = [
    {item:1 , date:'7th August',list:['Online Round Deadline']},
    {item:2 , date:'30th August',list:['Final Round']},
    {item:3 , date:'',list:['']}
]

export {prizes, contacts , schedule}