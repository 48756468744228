const prizes = [
    {position:'Winner' , prize:'Rs.30,000/-'},
    {position:'1st Runner Up' , prize:'Rs.20,000/-'},
]
const contacts = [
    {name:'Veenas Jaiswal',phone:'+91 9120410232'},
    {name:'Devansh Singh',phone:'+91 8081823698'},
    {name:'',phone:''},
]
const schedule  = [
    {item:1 , date:'25th July',list:['Preliminary Round']},
    {item:2 , date:'27th July',list:['Corporate Round']},
    {item:3 , date:'29th August',list:['Presentation Round']}
]

export {prizes, contacts , schedule}