const prizes30 = [
    {position:'Winner' , prize:'Rs.60,000/-'},
    {position:'1st Runner Up' , prize:'Rs.40,000/-'},
    {position:'2nd Runner Up' , prize:'Rs.30,000/-'}
]
const prizes15 = [
    {position:'Winner' , prize:'Rs.50,000/-'},
    {position:'1st Runner Up' , prize:'Rs.35,000/-'},
    {position:'2nd Runner Up' , prize:'Rs.25,000/-'}
]
const contacts = [
    {name:'Techniche IIT Guwahati',phone:'+91 87507 91896'},
    {name:'Naman Karwa',phone:'+91 93777 80309'},
    {name:'Khushi Aggarwal',phone:'+91 81468 43309'},
]
const schedule  = [
    {item:1 , date:'30th August',list:['Knockouts']},
    {item:2 , date:'31st August',list:['Quater Finals','Semi-Finals','Final Showdown']},
    {item:3 , date:'',list:['']},
    // {item:3 , date:'2nd September',list:['Workshop','Closing Ceremony','Hackathon(Session-III)']}
]

export {prizes15 , prizes30 , contacts , schedule}


