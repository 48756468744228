
import './Home.css'
import React from 'react';
import Journey from './components/Journey.js';
import Merch from './components/Merch.js';
import HeroLanding from './components/Hero-Landing.js';
import Journey_phone from './components/Journey_phone.js';
import Footer from '../../Footer/Footer.js';
function Home() {
  // useEffect(() => {
  //   // Initialize Lenis
  //   const lenis = new Lenis({
  //     duration: 1.2,
  //     easing: (t) => t,
  //     smoothWheel: true,
  //     smoothTouch: false
  //   });

  //   // Animation frame update
  //   const update = (time) => {
  //     lenis.raf(time);
  //     requestAnimationFrame(update);
  //   };

  //   // Start animation frame loop
  //   requestAnimationFrame(update);

  //   // Cleanup on component unmount
  //   return () => {
  //     cancelAnimationFrame(update); // Ensure this cancels the animation frame loop
  //     lenis.destroy(); // Clean up Lenis instance
  //   };
  // }, []);
  return (
    <div className="LandingPage">
      <HeroLanding/>
      {/* <EventVideo/> */}
      {window.innerWidth<740?<Journey_phone/>:null}
      <Journey/>
      {/* <Exciting_events/> */}
      <Merch/>
      <Footer/>
    </div>
   
  );
}


  
  
 
export default Home;