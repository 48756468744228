import React, { useState, useEffect } from "react";
import SearchBar from "./SearchBar";
import "./AllUsers.css";

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const FindUser = () => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(baseURL+"/caauth/getAllUsers");
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const json = await response.json();
            setResults(json);
        } catch (error) {
            setError("Error fetching data. Please try again later.");
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="user-container">
            <SearchBar setResults={setResults} />
            <div className="message-from-wo">
                <p><b>Message from Web Operations:</b> We didn't make a phone view for this page. Use your laptops for the admin panel.</p>
                <p><b>Message from Web Operations:</b> Refrain from sharing the link to this page outside Public Relations Core Team Techniche</p>
            </div>
            <div className="results-container">
                <div className="result-heading">
                    <p>ID</p>
                    <p>Name</p>
                    <p>Email</p>
                    <p>Contact</p>
                    <p>Techniche ID</p>
                    <p>Points</p>
                    <p>Country</p>
                    <p>State</p>
                </div>
                {loading && <p>Loading...</p>}
                {error && <p className="error-message">{error}</p>}
                {!loading && !error && results.length > 0 ? (
                    results.map((result, index) => (
                        <div key={index} className="result-item">
                            <p>{result.id}</p>
                            <p>{result.name}</p>
                            <p>{result.email}</p>
                            <p>{result.contact}</p>
                            <p>{result.t_id}</p>
                            <p>{result.points}</p>
                            <p>{result.country}</p>
                            <p>{result.state}</p>
                        </div>
                    ))
                ) : (
                    !loading && !error && <p>No results found</p>
                )}
            </div>
        </div>
    );
};

export default FindUser;
