const YearSlide = ({year,color})=>{
    return(
        <div className="year-scroll" style={{transform:year.id%2===0?'translateX(-50px)':''}}>
           <span style={{color:color}}>{year.year}</span>
           <span style={{WebkitTextStrokeWidth:'1px',WebkitTextStrokeColor:color,color:'transparent'}}>{year.year}</span>
           <span style={{color:color}}>{year.year}</span>
           <span style={{WebkitTextStrokeWidth:'1px',WebkitTextStrokeColor:color,color:'transparent'}}>{year.year}</span>
           <span style={{color:color}}>{year.year}</span>
           <span style={{WebkitTextStrokeWidth:'1px',WebkitTextStrokeColor:color,color:'transparent'}}>{year.year}</span>
           <span style={{color:color}}>{year.year}</span>
           <span style={{WebkitTextStrokeWidth:'1px',WebkitTextStrokeColor:color,color:'transparent'}}>{year.year}</span>
        </div>
    )
  }

  export default YearSlide