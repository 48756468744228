const prizes = [
    {position:'Winner' , prize:'Rs.70,000/-'},
    {position:'1st Runner Up' , prize:'Rs.40,000/-'},
    {position:'2nd Runner Up' , prize:'Rs.30,000/-'}
]
const contacts = [

    {name:'Puja',phone:'+91 9508428929'},
    {name:'Sayan',phone:'+91 7044699262'},
    {name:'',phone:''},
]
const schedule  = [
    {item:1 , date:'20th July',list:['Submissions Deadline']},
    {item:2 , date:'21st July',list:['Preliminary round result']},
    {item:3 , date:'30th August - 1st Septemper',list:['Presentataion', 'Exhibition']}
]

export {prizes, contacts , schedule}