import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { Link, useNavigate } from 'react-router-dom'
const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";
const RobowarsConfirmation = () => {
   const { ciphertext } = useParams();
   const decryptedID = ciphertext.substring(10, 15);
   console.log(decryptedID);

    useEffect(() => {
        const postData = async () => {
            try {
                const response = await axios.post(baseURL + '/robowars/payment', {
                    Decrypted_id: decryptedID,
                    paymentStatus: 'success'
                });
                console.log(response.data); 
            } catch (error) {
                console.error('Error:', error);
            }
        };

        postData(); 
    }, [decryptedID]);

    return (
        <div style={{height:'100vh'}}>
        <div className="bluebox" style={{paddingLeft:'5vw', paddingRight:'5vw'}}>
                <p className='please-login' style={{textAlign:'center', fontSize:'2.5rem'}}>Your payment Robowars 2024 was successful.<br />
                Please check your email for the confirmation mail and stay tuned for further updates.</p>
                <div className="button-container-wrapper">
                    <div className='button-container' >
                        <Link to="/login"><button type='submit' style={{backgroundColor:'#F3FF63'}}>Login</button></Link>
                    </div>

                </div>
        </div>
    </div>
    );
}

export default RobowarsConfirmation;