import teamCross from '../../../assets/teamCross.png';
import teamKuch from '../../../assets/teamKuch.png';
import { useState} from 'react';
import { Link } from 'react-router-dom';

const TeamCard = ({ head }) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <a href={head.linkdn}>
        <div className="head-card-wrapper" style={{ overflow: 'hidden', position: 'relative' }} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <div className='head-img-wrapper' style={{transform: 'scale(1)',  transition: 'transform 0.3s ease'  }}>
                <img src={head.url} alt='' />
            </div>
            <div className="team-ribbon-wrapper" style={{ position: 'absolute', bottom: window.innerWidth > 600 ? '40px' : '20px', left: '-5px' }}>
                <div style={{ width: '100%', overflowX: 'hidden', backgroundColor: '#F3FF63', marginBottom: window.innerWidth > 600 ? '5px' : '5px' }}>
                    <p className={(isHover || window.innerWidth < 500) ? "head-name-ribbon" : ''}  style={{ width: 'max-content', color: '#2D48D5', fontSize: window.innerWidth > 600 ? '1rem' : '0.7rem', paddingLeft: '5px', fontWeight:'100', fontFamily:"FK_Grotesk_SemiMono" }}>
                        {head.name}<img style={{ display: 'inline', margin: '0px 10px' }} src={teamCross} alt='' />
                        {head.name}<img style={{ display: 'inline', margin: '0px 10px' }} src={teamCross} alt='' />
                    </p>
                </div>
                <div style={{ width: '100%', overflowX: 'hidden', backgroundColor: '#2D48D5' }}>
                    <p className={(isHover || window.innerWidth < 500) ? 'head-team-ribbon' : ''} style={{ width: 'max-content', fontSize: window.innerWidth > 600 ? '0.9rem' : '0.6rem', fontWeight:'100', fontFamily:"FK_Grotesk_SemiMono" }}>
                        <img style={{ display: 'inline', margin: '0px 15px' }} src={teamKuch} alt='' />{head.team}
                        <img style={{ display: 'inline', margin: '0px 15px' }} src={teamKuch} alt='' />{head.team}
                    </p>
                </div>
            </div>
        </div>
        </a>
    );
};

export default TeamCard;
