import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar.js";
import "./newpage.css";
import photo from "../GhmAssets/image 367.png";
import photo2 from "../GhmAssets/dh1.png";
import FigLeft from "../GhmAssets/fig-left.png";
import FigRight from "../GhmAssets/fig-right.png";
import facebooklogo from "../GhmAssets/Group 783.png";
import linkedinlogo from "../GhmAssets/Group 785.png";
import twitterlogo from "../GhmAssets/Group 786.png";
import youtubelogo from "../GhmAssets/Group 788.png";
import instalogo from "../GhmAssets/Group 35057.png";
import Ribbon1 from "../GhmAssets/ribbon-3.png";
import Ribbon2 from "../GhmAssets/aboutr2.png";
import R1 from "../GhmAssets/aboutribbon1.png";
import R2 from "../GhmAssets/aboutribbon2.png";
import R3 from "../GhmAssets/aboutribbon3.png";
import Marque from "./Marque.js";

function AboutGhm() {
  const scope2 = useRef(null);
  const [showBetter, setShowBetter] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowBetter((prev) => !prev); // Toggle the state between true and false
    }, 1000); // Change interval duration to 1000ms (1 second)

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);
  return (
    <div className="newpage">
      <div className="ribbon ra-1">
        <img src={R1} alt="="></img>
      </div>
      <div className="ribbon ra-2">
        <img src={R2} alt="="></img>
      </div>
      <div className="ribbon ra-3">
        <img src={R3} alt="="></img>
      </div>
      <Navbar />
      {/* <div className="aboutribbon ar-1"><img src={Ribbon1} alt="" /></div>
            <div className="aboutribbon ar-2"><img src={Ribbon2} alt="" /></div> */}
      <div className="tag-section-about">
        <div className="runners run-left">
          <img src={FigLeft} alt="" />
        </div>
        <div className="tagline-container tagline-conatiner-about">
          <h1 className="tag-normal">RUN</h1>
          <h1 className="tag-normal">FOR A</h1>
          <h1 className="tag-italic">CLEANER</h1>
          <h1 className="tag-italic">TOMORROW</h1>
          <div className="tagline-description">
            <h4>
              The Guwahati Half Marathon 2024 Seeks To Highlight The Urgent Need
              To Address The Issue Of Cleanliness And Sanitation Of Guwahati.
            </h4>
          </div>
        </div>
        <div className="runners run-right" id="fig-right">
          <img src={FigRight} alt="" />
        </div>
      </div>
      <div className="aboutghmcontent">
        <div className="page">
          <div className="text">
            <div className="p-1">
              <span className="bold">The Guwahati Half Marathon</span> is an
              unwavering commitment by Techniche IIT Guwahati to support and
              uplift a social issue of relevance in our times. We aim to not
              only make the general public aware of this issue, but also provide
              means and measures every single citizen can implement.
            </div>
            <div className="p-2">
              Every year, a Sustainable Development Goal as proposed by the UN,
              is carefully chosen keeping in mind the social situation of
              Guwahati at the time and the level of justice we can do to the
              issue.
            </div>
          </div>
          <div className="photo">
            <img src={photo}></img>
          </div>
        </div>
        <div className="marq" ref={scope2}>
          <div className="marque-text-container">
            <h1 className={`marque-text ${showBetter ? "better" : "cleaner"}`}>
              {showBetter ? "Better" : "Cleaner"}
            </h1>
          </div>
          <Marque />
        </div>
        <div className="page2">
          <div className="photo2">
            <img src={photo2}></img>
          </div>
          <div className="text2">
            <div className="p-1">
            This year, experience the exhilaration of the Guwahati Half Marathon, where each step forward isn't just about personal achievement—it's about elevating our city. As you challenge yourself on the course, consider the parallel journey we're on to enhance Guwahati's cleanliness. Let's unite in this dual pursuit of self-improvement and city enhancement, realizing that by bettering ourselves, we're also enhancing the community we call home. Together, let's lace up, push boundaries, and make strides towards a brighter future for Guwahati.{" "}
            </div>
            <div className="p-2">
            So, when you join us, you're not just running a race; you're taking a stand for cleanliness and wellness in our city. We want every participant to feel empowered to embrace personal hygiene practices, like proper handwashing and waste disposal, because we know that these small actions can make a big difference.
            </div>
          </div>
        </div>
        <div className="marq" ref={scope2}>
          <div className="marque-text-container">
            <h1 className={`marque-text ${showBetter ? "better" : "cleaner"}`}>
              {showBetter ? "Better" : "Cleaner"}
            </h1>
          </div>
          <Marque />
        </div>
        <div className="registernowlink">
          <Link to="/ghm/register">
            REGISTER <i>NOW</i>
          </Link>
        </div>
      </div>
      <div className="footer-ghm">
        <div className="vector">
          <a
            href="https://www.instagram.com/techniche_iitguwahati?igsh=YTh3NWRmbmpwbTRu"
            className="instalogosghm"
          >
            <img src={instalogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a
            href="https://www.facebook.com/techniche.iitguwahati/"
            className="facebooklogosghm"
          >
            <img src={facebooklogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a
            href="https://in.linkedin.com/company/techniche-iitg"
            className="linkedinlogosghm"
          >
            <img src={linkedinlogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a href="" className="twitterlogosghm">
            <img src={twitterlogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a
            href="https://www.youtube.com/user/technicheiitg"
            className="youtubelogosghm"
          >
            {" "}
            <img src={youtubelogo} style={{ width: "100%", height: "100%" }} />
          </a>
        </div>
        <div className="lowerfooter">
          <div className="leftside">
            <div className="seeyouatthe">
              <p className="startingline">
                See you at the <i>starting line</i>
              </p>
            </div>
            <div className="writetous">
              <h1 id="writeto">Write to Us</h1>
              <a href="" id="mailtechniche">
                ✉️info@technicheiitg.in    
              </a>
            </div>
          </div>
          <div className="rightside">
            <div className="quicklinksblock">
              <h2 className="quicklinksheading">Quick Links</h2>
              <p className="linksghm">
                <a
                  href="https://techniche.org/ghm/register"
                  className="linkitems"
                >
                  Register Now
                </a>
              </p>
              <p className="linksghm">
                <a href="https://techniche.org/ghm/about" className="linkitems">
                  About GHM
                </a>
              </p>
              <p className="linksghm">
                <a href="https://techniche.org/ghm/eventdetails" className="linkitems">
                  Event Details
                </a>
              </p>
            </div>
            <div className="contactusghm">
              <h2 className="quicklinksheading">Contact Us</h2>
              <p className="contact-details">Shikhar Mishra</p>
              <a className="contact-number" href="tel:+918690624922">
                +91 8690624922
              </a>
              <p className="contact-details">Divyanshu Tiwari</p>
              <a className="contact-number" href="tel:+918750791896">
                +91 8750791896
              </a>
              <p className="contact-details">Rachit Shah</p>
              <a className="contact-number" href="tel:+917389007970">
                +91 7389007970
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutGhm;
