const prizes = [
    {position:'Winner' , prize:'Rs.25,000/-'},
    {position:'1st Runner Up' , prize:'Rs.15,000/-'},
    {position:'2nd Runner Up' , prize:'Rs.10,000/-'}
]
const contacts = [
    {name:'Shubham Kumar',phone:'+91 8207536940'},
    {name:'Kamkaksh Gurjar',phone:'+91 8207536940'},
    {name:'',phone:''},
]
const schedule  = [
    {item:1 , date:'5th August',list:['Final Date of Submissions']},
    {item:2 , date:'30th August',list:['Final Round']},
    {item:3 , date:'',list:['']}
]

export {prizes, contacts , schedule}