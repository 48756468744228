import React from 'react'
import merch from '../assets/merch.png';
import {Link} from "react-router-dom"
import Footer
 from '../../../Footer/Footer';
const Merch = () => {
  return (
    <div>
      <div className='Merch'>
      <div className="Merch-img-block">
        <img src={merch} id='merch_img1' alt="merch" />
      </div>
      <div className="Merch-text">
        <p className="collection-txt">2024 Collection</p>
        <p  className="elevate-your-style" > Elevate your <span style={{fontStyle:"italic",color:"#F3FF63"}}>Style</span> with</p>
        <p  className="elevate-your-style2" > Techniche Merch!</p>
        <p className="merch-body-txt">Check out our curated collection of Merchandise</p>
        <div className="merch-btn-container" >
            <Link to="/merch" style={{height: '12vh'}}>
              <div className='merch-btn-wrapper' style={{backgroundColor:'black',width:'max-content', padding:'0.05vh', display:'flex', justifyContent:'center', alignItems:'center', }}>
                  <span className='merch-btn'>Explore</span>
              </div>
            </Link >
        </div>
      </div>
    </div>
    {/* <Footer/> */}
    </div>
  )
}

export default Merch