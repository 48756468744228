import React, { useState, useEffect } from 'react';
import "./CALogin.css"
import { Link } from 'react-router-dom'
import { redirect, useNavigate } from "react-router-dom";
import axios from 'axios'
import { useUserContext } from './UserContext';
import { useAuth } from '../../../../services/authContext';
import Navbar from '../../CampusAmbassdor/CA-Home/nav';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";
//console.log(baseURL + 'caauth/login');

const CALoginForm = () => {
  const { login } = useAuth();
  const auth = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })
  const [sanitizedHash, setSanitizedHash] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {

      //loginmain({ name, t_id, email, sanitizedHash });
      //localStorage.setItem('isLoggedInMain', true);


      const response = await axios.post(baseURL + '/caauth/login', formData);
      const { sanitizedHash, name, token, t_id, email } = response.data;
      login(token,{name, t_id, email, sanitizedHash,token });
      localStorage.setItem('isLoggedInCA', true);

      //auth.login(token, { sanitizedHash, name, t_id, token, email });
      navigate(`/cadashboard/${sanitizedHash}/viewtasks`);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setErrorMessage('Please verify your email before logging in.');
      } else {
        setErrorMessage('Login failed. Please check your credentials.');
      }
    }
  };

  useEffect(() => {
  }, [sanitizedHash]);

  return (
    <div className='login-new'>
      <Navbar/>      
      <div className="login-new-container">
        <p className='title'>Welcome Back!</p>
        <h4>Login to access your CA portal</h4>
        <form onSubmit={handleSubmit}>

          <div className='form-option'>
            <label for="email">Email</label>
            <input type="text" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required /><br />
          </div>
          <div className='form-option'>
            <label for="password">Password</label>
            <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required /><br />
          </div>
          {errorMessage && <p className='error-message'>{errorMessage}</p>}
          <button className='login-button-white'><p>Log In</p></button><br />
          <Link to="/casignup"><button className='signup-button-orange'><p>Don't have an account? Signup</p></button></Link>
        </form>
      </div>
    </div>
  );
}

export default CALoginForm;


// import React, { useState, useEffect } from 'react';
// import "./CALogin.css"
// import leftHand from "../../Login/images/Left_hand.png"
// import rightHand from "../../Login/images/Right_hand.png"
// import plus from "../../Login/images/plus.png"
// import CAHeaderLogin from '../CA-HeaderLogin/ca-headerlogin';
// import { Link} from 'react-router-dom'
// import { redirect, useNavigate } from "react-router-dom";
// import axios from 'axios'
// import { useUserContext } from './UserContext';
// import { useAuth } from '../../../../services/authContext';

// const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";
// console.log(baseURL+'caauth/login');

// const CALoginForm = () => {
//   const { login } = useAuth();
//   const auth = useAuth();
//   const [formData, setFormData] = useState({
//     email: '',
//     password: ''
//   })
//   const { setUser } = useUserContext();
//   const [sanitizedHash, setSanitizedHash] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');
//   const navigate = useNavigate();

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//   };
//   const handleSubmit = async (event) => {
//     event.preventDefault();
  
//     try {
//       const response = await axios.post(baseURL+'/caauth/login', formData);
//       const { sanitizedHash, name, token, t_id,email } = response.data;
//       localStorage.setItem('user', JSON.stringify({ sanitizedHash, name, t_id,email }));
  
//       auth.login(token, { sanitizedHash, name, t_id,token,email });
//       navigate(`/cadashboard/${sanitizedHash}`);
//     } catch (error) {
//       if (error.response && error.response.status === 403) {
//         setErrorMessage('Please verify your email before logging in.');
//       } else {
//         setErrorMessage('Login failed. Please check your credentials.');
//       }
//     }
//   };

//   useEffect(() => {
//   }, [sanitizedHash]);

//   return (
//     <div className='parent relative'>
//         <CAHeaderLogin/>
//         <img src={leftHand} className='hover fixed left-0 top-0 h-[60vh] '/>
//         <img src={rightHand} className='hover fixed -right-[50px] bottom-0 h-[60vh] '/>
//         <img src={plus} className='fixed my-auto left-2'/>
//         <img src={plus} className='fixed my-auto right-2'/>


//         <div className="login-form mt-30 md:mt-20">
//         <p className='heading text-6xl'>Welcome Back!</p>
//         <h4>Login to acess your CA portal</h4>
//         <form onSubmit={handleSubmit}>
//             <input
//             type="text"
//             name="email"
//             placeholder="Email"
//             value={formData.email}
//             onChange={handleChange}
//             required
//             /><br />
//             <input
//             type="password"
//             name="password"
//             placeholder="Password"
//             value={formData.password}
//             onChange={handleChange}
//             required
//             /><br />
//             {errorMessage && <p className='text-sm text-red-500 pt-2'>{errorMessage}</p>}
//             <button className='loginx  bg-gradient-to-r from-blue-500 to-violet-900'>Sign In</button><br />
//             <Link to="/casignup"><button className='w-[60%] p-3 mt-6 rounded-full bg-transparent border-2 border-gray-400'>Don't have an account? Signup</button></Link>
//         </form>
//         </div>
//     </div>
//   );
// }

// export default CALoginForm;