import React, { useEffect, useState, useRef } from "react";
import "./TrackTitansregister.css";
import leftHand from "../../TechExpo/images/Left_hand.png"
import rightHand from "../../TechExpo/images/Right_hand.png"
import plus from "../../TechExpo/images/plus.png"
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../services/authContext';
import axios from 'axios'
const CryptoJS = require("crypto-js");



const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const TrackTitansregister = () => {
    const { isLoggedInMain, userDataMain, setUserDataMain, loginmain, logoutmain } = useAuth();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({
        Name: '',
        Contact: '',
        Email: '',
        Dob: '',
        Address: '',
        City: '',
        State: '',
        Country: '',
        Pincode: '',
        College: '',
        Course: '',
        YearOfStudy: '',
        TeamName: '',
        Category: '',
        IdProof: '',
        MentorDesignation: '',
        MentorContact: '',
        MentorEmail: '',
        ReferralCode: ''
    });
    const [teamMembers, setTeamMembers] = useState([]);
    const [submitted, setSubmitted] = useState(false);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(baseURL + '/tracktitans/register', formData);
            const { TRACKTITANS_ID } = response.data;
            console.log(TRACKTITANS_ID);
            const ciphertext = CryptoJS.AES.encrypt(TRACKTITANS_ID, "iitgtechniche").toString().replace(/\//g, '_');
            const lastFiveLetters = TRACKTITANS_ID.slice(-5);
            console.log(lastFiveLetters);
            const hash = CryptoJS.SHA256(TRACKTITANS_ID).toString();
            let truncatedHash;
            if (hash.length > 10) {
                truncatedHash = hash.substring(0, 10);
            } else if (hash.length < 10) {
                truncatedHash = hash.padEnd(10, '_'); // Example: pad with underscores
            } else {
                truncatedHash = hash;
            }
            // const redirectUrl = `https://techniche.org/robotics/robowars/confirm/${ciphertext}${lastFiveLetters}`;
            const redirectUrl = `https://techniche.org/robotics/tracktitans/confirm/${truncatedHash}${lastFiveLetters}${ciphertext}`;
            console.log(redirectUrl);
            window.location.href = 'https://www.meraevents.com/ticketWidget?eventId=260704&ucode=organizer&wcode=9063CD-9063CD-333333-9063CD-&theme=1&redirectUrl=' + redirectUrl;
        } catch (error) {
            let errorMessage = '';
            if (error.response && error.response.status === 409) {
                errorMessage = 'Your team has already registed for the Track Titans. Kindly check your email for further information.';
            }
            else {
                errorMessage = 'Registration failed. Please check your credentials.';
            }
            setErrorMessage(errorMessage);
        }
    };

    return (
        <div className='loginbackground'>
            {isLoggedInMain ? (
                <div className="bluebox">
                    {submitted ? ( // If submitted is true, show the dummy text
                        <p className="succesfull-text" style={{marginTop: "10%"}}>You have successfully registered for Track Titans 2024! Check your email for confirmation.<br /> Incase you haven't received the email please check your spam folder.</p>
                    ) : (
                        <>
                                <p>Register for Track Titans</p>
                                <form onSubmit={handleSubmit} className="form-container">
                                    <div className="form-group">
                                        <div className="form-detailss">
                                            <p>Team Leader Details</p>
                                        </div>
                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Name</div>
                                            <input type="text" placeholder="enter full name" name="Name" value={formData.Name} onChange={handleChange} required />
                                        </div>

                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Age</div>
                                            <input type="text" placeholder="enter age" name="Contact"value={formData.Contact} onChange={handleChange} required />
                                        </div>

                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Email</div>
                                            <input type="text" placeholder="enter email" name="Email"  required value={formData.Email} onChange={handleChange} />
                                        </div>

                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Date of birth</div>
                                            <input type="text" name="Dob" placeholder="enter date of birth (dd-mm-yyy format)" required value={formData.Dob} onChange={handleChange} />
                                        </div>

                                        <div className='input-feild-wrapper'>
                                        <div className='input-feild-indicator'>Address</div>
                                            <input type="text" name="Address" placeholder="enter your address" required value={formData.Address} onChange={handleChange} />
                                        </div>

                                        <div className='input-feild-wrapper'>
                                        <div className='input-feild-indicator'>City</div>
                                            <input type="text" name="City" placeholder="enter your city" required value={formData.City} onChange={handleChange} />
                                        </div>

                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>State</div>
                                            <input type="text" name="State" placeholder="enter your state" required value={formData.State} onChange={handleChange} />
                                        </div>

                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Country</div>
                                            <input type="text" name="Country" placeholder="enter your country" required value={formData.Country} onChange={handleChange} />
                                        </div>

                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Pincode</div>
                                            <input type="text" name="Pincode" placeholder="enter your pincode" required value={formData.Pincode} onChange={handleChange} />
                                        </div>

                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>College</div>
                                            <input type="text" name="College" placeholder="enter your college/institution (na if not applicable)" required value={formData.College} onChange={handleChange} />
                                        </div>
                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Course</div>
                                            <input type="text" name="Course" placeholder="enter your course name (na if not applicable)" required value={formData.Course} onChange={handleChange} />
                                        </div>
                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Year Of Study</div>
                                            <input type="text" name="YearOfStudy" placeholder="enter your year of study (na if not applicable) " required value={formData.YearOfStudy} onChange={handleChange} />
                                        </div>
                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Upload Your Student ID</div>
                                            <input type="text" name="IdProof" placeholder="upload link (ensure view access)" required value={formData.IdProof} onChange={handleChange} />
                                        </div>

                                        <div className="form-detailss">
                                            <p>Team Details</p>
                                        </div>

                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Team Name</div>
                                            <input type="text" name="Organization" placeholder="enter your team name" required onChange={handleChange} />
                                        </div>

                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Number of Team Members</div>
                                            <select onChange={handleChange} required>
                                                <option value="" disabled selected>Select number of members</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </select>
                                        </div>
                                        <div className="form-detailss">
                                            <p>Mentor Details(if any)</p>
                                        </div>
                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Mentor Designation</div>
                                            <input type="text"  name="MentorDesignation" placeholder="Mentor" value={formData.MentorDesignation} onChange={handleChange} />
                                        </div>
                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Mentor Contact</div>
                                            <input type="text" name="MentorContact" placeholder="enter your mentor's contact no." value={formData.MentorContact} onChange={handleChange} />
                                        </div>
                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Mentor Email</div>
                                            <input type="text" name="MentorEmail" placeholder="enter your mentor's email" value={formData.MentorEmail} onChange={handleChange} />
                                        </div>

                                        <div className="form-detailss">
                                            <p>Referral code(if applicable)</p>
                                        </div>
                                        <div className='input-feild-wrapper'>
                                            <div className='input-feild-indicator'>Referral Code</div>
                                            <input type="text" name="ReferralCode" placeholder="referral code (T024XXXXX)" value={formData.ReferralCode} onChange={handleChange} />
                                        </div>
                                    </div>

                                    {errorMessage && <p style={{textAlign:'center', fontSize:'2rem'}}>{errorMessage}</p>}
                                    <div className="button-container-wrapper-outter">
                                    <div className="button-container-wrapper">
                                    <div className='button-container'>
                                        <button onClick={handleSubmit} type='submit'>Proceed to Pay</button>
                                    </div>
                                    </div>
                                    </div>
                                    
                                    
                                </form>
                        </>
                    )}
                </div>
            ) : (
                <div style={{height:'100vh'}}>
                    <div className="bluebox" style={{paddingLeft:'5vw', paddingRight:'5vw'}}>
                            <p className='please-login' >You must be logged in to your techniche account to register for Track Titans 2024.</p>
                            <div className="button-container-wrapper-outter">
                            <div className="button-container-wrapper">
                            <div className='button-container'>
                                    <Link to="/login"><button type='submit' style={{backgroundColor:'#F3FF63'}}>Login</button></Link>
                                </div>

                            </div>
                            </div>
                    </div>
                </div>
            )}
        </div>

    )
}


export default TrackTitansregister;