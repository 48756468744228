import { useEffect, useRef, useState } from "react";
import { FaPlay, FaPause, FaAngleLeft } from "react-icons/fa";
import { motion } from "framer-motion";

const VideoPlayer = ({ src, handleExit, open, setOpen }) => {
  const videoRef = useRef(null);
  const intervalRef = useRef(null);
  const [isPlaying, setIsplaying] = useState(true);
  const [progress, setProgress] = useState(0);

  const updateProgress = () => {
    if (videoRef.current) {
      const value =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(value);
    }
  };

  const startProgressLoop = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      updateProgress();
    }, 500);
  };

  const stopProgressLoop = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsplaying(true);
        startProgressLoop();
      } else {
        videoRef.current.pause();
        setIsplaying(false);
        stopProgressLoop();
      }
    }
  };

  const handleSeek = (e) => {
    const seekto = (e.target.value / 100) * videoRef.current.duration;
    videoRef.current.currentTime = seekto;
    setProgress(e.target.value);
  };

  useEffect(() => {
    const ribbon1 = document.querySelector(".r-1");
    const ribbon2 = document.querySelector(".r-2");
    const ribbon3 = document.querySelector(".r-3");
    // const gone = document.querySelector(".play");
    // console.log(ribbon.style);
    // gone.addEventListener("click",()=>{
    //   ribbon1.style.display = "none";
    //   ribbon2.style.display = "none";
    //   ribbon3.style.display = "none";
    // })
    if (open) {
      const exit = document.querySelector(".exit-video");
      exit.addEventListener("click", () => {
        ribbon1.style.display = "block";
        ribbon2.style.display = "block";
        ribbon3.style.display = "block";
      });
    }
  }, [open]);

  const VideoControls = () => {
    return (
      <>
        <div className="controls-wrapper">
          <div className="progress">
            <input
              type="range"
              min="0"
              max="100"
              value={progress}
              onChange={handleSeek}
            />
          </div>
          <div className="controls">
            <div className="play-pause">
              <button onClick={togglePlayPause}>
                {!isPlaying ? <FaPlay /> : <FaPause />}
              </button>
              <p>
                {Array.from(videoRef.current.currentTime.toString())
                  .slice(0, 4)
                  .join("")}
                /
                {Array.from(videoRef.current.duration.toString())
                  .slice(0, 5)
                  .join("")}
              </p>
            </div>
            <button className="exit-video" onClick={handleExit}>
              <FaAngleLeft />
              Exit Video{" "}
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="video">
        <div className="black"></div>
        <video
          className="vid"
          ref={videoRef}
          src={src}
          onClick={togglePlayPause}
          onPlay={startProgressLoop}
          onPause={stopProgressLoop}
          controls={false}
        />
        {open && (
          <motion.div
            className="motion-controls"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5, at: "<" }}
          >
            <VideoControls />
          </motion.div>
        )}
      </div>
    </>
  );
};

export default VideoPlayer;