import bInsta from '../assets/b-insta.png'
import bLnkdn from '../assets/b-lnkdn.png'
import bMdm from '../assets/medium-hvr.png'
import bX from '../assets/b-x.png'
import bYt from '../assets/b-yt.png'
import insta from '../assets/insta.png'
import lnkdn from '../assets/lnkdn.png'
import mdm from '../assets/mdm.png'
import x from '../assets/x.png'
import yt from '../assets/yt.png'

export const data = [
    {normal:insta , hover:bInsta , url:'https://www.instagram.com/techniche_iitguwahati/?hl=en'},
    {normal:lnkdn , hover:bLnkdn , url:'https://in.linkedin.com/company/techniche-iitg'},
    {normal:x , hover:bX, url:'https://twitter.com/Techniche_IITG'},
    {normal:yt , hover:bYt , url:'https://www.youtube.com/c/techniche'},
    {normal:mdm , hover:bMdm , url:'https://media-techniche.medium.com/'}
]