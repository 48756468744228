import React from "react";
import { Link } from "react-router-dom";
import './LongTile.css';

const Tile = (props) => {

    return ( 
            <div className="LongTileWrapper">
                <div className="LongTileContainer">
                    <img className="LongTileImage" src={props.img} alt="Blockchain" />
                    <p style={{color: props.color, marginBottom:'2vh'}}>{props.title}</p>
                    <Link to={`${props.link}`}>
                        <div className="RegWrapper">
                            <span className="RegContainer">
                            Register Now
                            </span>
                        </div>
                    </Link>
                </div>
            </div>
        
           
     );
}

 
export default Tile;