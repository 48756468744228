import { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [sanitizedHash, setSanitizedHash] = useState(null);

  return (
    <UserContext.Provider value={{ user, setUser, sanitizedHash, setSanitizedHash }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
