import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";
const EscaladeConfirmation = () => {
   const { ciphertext } = useParams();
//    const bytes = CryptoJS.AES.decrypt(ciphertext, 'iitgtechniche');
   const decryptedID = ciphertext.substring(10, 15);
   console.log(decryptedID);

    useEffect(() => {
        const postData = async () => {
            try {
                const response = await axios.post(baseURL + '/escalade/payment', {
                    Decrypted_id: decryptedID,
                    paymentStatus: 'success'
                });
                console.log(response.data); 
            } catch (error) {
                console.error('Error:', error);
            }
        };

        postData(); 
    }, [decryptedID]);

    return (
        <div style={{ color: 'black',
        height:"100vh",
        backgroundColor:"black",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        rowGap:"10vh"


        
         }}>
            <div className='message'>
                Your payment for ESCALADE 2024 was successful.<br />
                Please check your email for the confirmation mail and stay tuned for further updates.
            </div>
            
        

        </div>
    );
}

export default EscaladeConfirmation;