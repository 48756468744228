import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Submission.css';
import { useAuth } from '../../../../services/authContext';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const SubmissionTT = () => {
    const { sanitizedHash } = useParams();
    const [teamData, setTeamData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userDataMain } = useAuth();

    return (
        <div>
            <div className='submission-container'>
                <div className='submission-heading'>
                    <p>Click below to submit the abstract</p>
                </div>
                <div className='registered-button'>
                    <Link to="https://docs.google.com/forms/d/e/1FAIpQLSeSQ1VktBsQdHp-e2J-Op4eTGXUXC_BQ0Xbq649V__ATxXbIQ/viewform?usp=sf_link" className='register transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500' style={{ fontSize: '1 rem', fontWeight: '200' , paddingTop:'2vh', paddingBottom:'2vh', paddingLeft:'2vw', paddingRight:'2vw', borderRadius:'5vh'}} >Submit Abstract</Link><br />
                </div>
                <div>
                    <p><a href="https://docs.google.com/document/d/1c-NbIIJKDqqlVybCfl4SEHJ16EWx6CNOBZMXBgUWWlo/edit#"><span style={{ color: '#0078CE' }}>click here to see abstract format.</span></a> </p>
                </div>

            </div>
            {/* <div className='submission-container-2'>
                <div className='submission-heading'>
                    <p>Click below to submit the video</p>
                </div>
                <div className='registered-button'>
                    <Link to="https://forms.gle/RN5X8aGpBdztRxtc9" className='register transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500' style={{ fontSize: '1 rem', fontWeight: '200' }} >Submit Video</Link><br />
                </div>

            </div> */}
        </div>
    );
};

export default SubmissionTT;
