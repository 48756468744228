import React from "react";
import Star from "./Images/Star 2.png"
import './ProfileCard.css'
import profimg from './Images/image 53.png'
import schoolimg from './Images/School.png'
import emailimg from './Images/Email.png'
import phoneimg from './Images/Phone.png'
import locationimg from './Images/Address.png'
const ProfileCard=({name,referral,profileimg,school,country,email,contact,points})=>{
    return(
        <div className="papa">
            <div className="card1">
            <div className="profile">
                <div className="profile_img">
                    <div className="stardiv">
                        {/* <img src={Star} alt="" className="star-img" /> */}
                        <img src={profileimg} alt="" className="face-img" />
                    </div>
                </div>
                <div className="nameandreferral">
                    <div className="usersname">{name}</div>
                    <div className="referral">{referral}</div>
                </div>
            </div>
            <div className="right-side">
            <div className="details">
                <div className="info">
                    <div><img src={schoolimg} alt="" className="smallimg" /></div>
                    <div className="info-para">{school}</div>
                </div>
                <div className="info">
                    <div><img src={locationimg} alt="" className="smallimg"  /></div>
                    <div className="info-para">{country}</div>
                </div>
                <div className="info">
                    <div><img src={emailimg} alt=""className="smallimg"  /></div>
                    <div className="info-para">{email}</div>
                </div>
                <div className="info">
                    <div><img src={phoneimg} alt=""className="smallimg"  /></div>
                    <div className="info-para">{contact}</div>
                </div>
                
            </div>
            </div>
        </div> 
        <div className="points-div">
            <div className="points">{points + ` PT's`}</div>
        </div>
        </div> 
    )
}

export default ProfileCard;