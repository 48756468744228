import React, { useEffect, useState } from "react";
import "./CATasks.css";
import axios from 'axios';
import CAHeaderLogin from "../CA-HeaderLogin/ca-headerlogin";
import submit from '../images/Group 60.svg';
import failed from '../images/Group58.svg';
import success from '../images/Group59.svg';
import submitagain from '../images/sumbitagain.svg';
import submitted from '../images/Group 190.png';
import verifying from '../images/Group 70.png';
import CADashboard from "../CADasboard/CADashboard";
import Navbar from '../../CampusAmbassdor/CA-Home/nav';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const CATaskUserSide = () => {
    const email = localStorage.getItem('caemail');

    const [pendingTasks, setPendingTasks] = useState([]);
    const [rejectedTasks, setRejectedTasks] = useState([]);
    const [acceptedTasks, setAcceptedTasks] = useState([]);
    const [nonsubmittedtask, setNonsubmittedTask] = useState([]);
    const [links, setLinks] = useState({});
    const [filter, setFilter] = useState("all");
    const [submissionModal, setSubmissionModal] = useState(false);
    const [congratulationModal, setCongratulationModal] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        fetchTasks();
    }, []);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fetchTasks = async () => {
        try {
            const response = await axios.post(baseURL + '/catasksupload/showusertasksupdated', {
                params: {
                    email: email
                }
            });

            const { pendingTasks, rejectedTasks, acceptedTasks, nonsubmittedtask } = response.data;

            setPendingTasks(pendingTasks);
            setRejectedTasks(rejectedTasks);
            setAcceptedTasks(acceptedTasks);
            setNonsubmittedTask(nonsubmittedtask);
        } catch (error) {
            console.log(error);
        }
    };

    const toggleSubmissionModal = () => {
        setSubmissionModal(!submissionModal);
    }

    const toggleCongratulationModal = () => {
        setCongratulationModal(!congratulationModal);
    }

    if (submissionModal || congratulationModal) {
        document.body.classList.add('active-modal');
    } else {
        document.body.classList.remove('active-modal');
    }

    const handleSubmit = async (taskId) => {
        try {
            if (!links[taskId]?.trim()) {
                alert("Link cannot be empty");
                return;
            }

            await axios.post(baseURL + '/catasksupload/submitTaskByUser', {
                taskId,
                link: links[taskId],
                params: {
                    email: email
                }
            });

            await axios.put(baseURL + '/catasksupload/updateDoneInCATask', {
                taskId,
                params: {
                    email: email
                },
            });

            fetchTasks();
            setLinks((prevLinks) => ({ ...prevLinks, [taskId]: "" }));
            setSubmissionModal(true); 
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmitAgain = async (taskId) => {
        try {
            if (!links[taskId]?.trim()) {
                alert("Link cannot be empty");
                return;
            }

            await axios.put(baseURL + '/catasksupload/submitCorrectLinkByUser', {
                taskId,
                link: links[taskId],
                email:email
                // params: {
                //     email: email
                // }
            });

            fetchTasks();
            setLinks((prevLinks) => ({ ...prevLinks, [taskId]: "" }));
            setSubmissionModal(true);
        } catch (error) {
            console.log(error);
        }
    };

    const handleLinkChange = (taskId, value) => {
        setLinks((prevLinks) => ({ ...prevLinks, [taskId]: value }));
    };

    const renderTask = (task, index, status) => (
        <div key={index} className="eachtask">
             <div className={`taskd ${windowWidth <= 600 ? 'small' : ''}`}>
                {windowWidth <= 600 ? (
                    <>
                        <div className="catags">
                            {status === "rejected" && <img src={failed} alt="failed" className="status-icon" />}
                            {status === "accepted" && <img src={success} alt="Accepted" className="status-icon" />}
                            {status === "pending" && <img src={verifying} alt="Pending" className="status-icon" />}
                        </div>
                        <div className="taskpoint">{task.points + ` PTs`}</div>
                        <div className="texttask">{task.task}</div>
                    </>
                ) : (
                    <>
                        <div className="texttask">{task.task}</div>
                        <div className="catags">
                            {status === "rejected" && <img src={failed} alt="failed" className="status-icon" />}
                            {status === "accepted" && <img src={success} alt="Accepted" className="status-icon" />}
                            {status === "pending" && <img src={verifying} alt="Pending" className="status-icon" />}
                        </div>
                        <div className="taskpoint">{task.points + ` PTs`}</div>
                    </>
                )}
            </div>
            <div className="cawork">{task.descriptions}</div>
            <div className="submission">
                <div className="linkforsubmit">
                    <input
                        type="text"
                        value={links[task.id] || ""}
                        onChange={(e) => handleLinkChange(task.id, e.target.value)}
                        className="linktask"
                        placeholder="Link to your work"
                        disabled={status === "accepted"}
                    />
                </div>
                <div className="btnforsubmit">
                    {status === "pending" && (
                        <input
                        type="image"
                        src={submitted}
                        alt="Resubmit"
                        onClick={() => handleSubmitAgain(task.id)}
                        className="status-icon"
                    />
                    )}
                    {status === "rejected" && (
                        <input
                            type="image"
                            src={submitagain}
                            alt="Resubmit"
                            onClick={() => handleSubmitAgain(task.id)}
                            className="status-icon1"
                        />
                    )}
                    {status === "accepted" && (
                        <img src={submitted} alt="Accepted" className="status-icon" />
                    )}
                    {status === "nonsubmitted" && (
                        <input
                            type="image"
                            src={submit}
                            alt="Submit"
                            onClick={() => handleSubmit(task.id)}
                            className="status-icon"
                        />
                    )}
                </div>
            </div>
        </div>
    );

    const filteredTasks = () => {
        let tasks = [];
        if (filter === "success") {
            tasks = acceptedTasks.map((task, index) => renderTask(task, index, "accepted"));
        } else if (filter === "failed") {
            tasks = rejectedTasks.map((task, index) => renderTask(task, index, "rejected"));
        } else if (filter === "pending") {
            tasks = pendingTasks.map((task, index) => renderTask(task, index, "pending"));
        } else {
            tasks = [
                ...nonsubmittedtask.map((task, index) => renderTask(task, index, "nonsubmitted")),
                ...rejectedTasks.map((task, index) => renderTask(task, index, "rejected")),
                ...pendingTasks.map((task, index) => renderTask(task, index, "pending")),
               ...acceptedTasks.map((task, index) => renderTask(task, index, "accepted"))
            ];
        }
        return tasks;
    };

    return (
        <div className="CApage">
            <Navbar/>
            <div className="taskpage">
                <div className="cadashboard-container">
                    <CADashboard />
                </div>
                <div className="dashtask">
                    <div className="taskdivhead">
                        <div className="taskheading">Tasks</div>
                        <div className="filtertaskbtn">
                            <select className="filteroption" value={filter} onChange={e => setFilter(e.target.value)}>
                                <option value="all" className="options">ALL TASKS</option>
                                <option value="success" className="options">SUCCESS</option>
                                <option value="failed" className="options">FAILED</option>
                                <option value="pending" className="options">PENDING</option>
                            </select>
                        </div>
                    </div>
                    <div className="taskdisplay">
                        {filteredTasks()}
                    </div>
                </div>
                {submissionModal &&
                    (<div className="modal">
                        <div onClick={toggleSubmissionModal} className="overlay">
                            <div className="modal-content">
                                <p className="modal-heading">Submitted!</p>
                                <h3 className="modal-desc">Your task has been submitted and will be verified shortly</h3>
                            </div>
                        </div>
                    </div>)
                }
                {congratulationModal && (
                    <div className="modal">
                        <div onClick={toggleCongratulationModal} className="overlay">
                            <div className="modal-content">
                                <p className="modal-heading">Congratulations!</p>
                                <h3 className="modal-desc " style={{ fontSize: '3vh' }}>An email has been sent to you with the collected reward attached to it!</h3>
                                <h6 className="modal-desc">(Contact us if you have not received the mail within 2-3 mins!)</h6>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CATaskUserSide;
