import React, { useEffect, useState } from "react";
import "./CAInputPr.css";
import axios from 'axios'
import CAHeaderLogin from "../../CA-HeaderLogin/ca-headerlogin";

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const CATASKSubmitForm = () => {
    const [taskData, setTaskData] = useState({
        task: '',
        points: '',
        descriptions: '',
        dateOfSub: '',
    });

    const handleChange = (e) => {
        setTaskData({ ...taskData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(baseURL + '/catasksupload/saveTaskForm', taskData);
            //console.log(response.data);
            alert("New task added!!");
            setTaskData({
                task: '',
                points: '',
                descriptions: '',
                dateOfSub: '',
            });
        } catch (error) {
            console.log(error);
            alert("BT hogyi :-<");
        }
    }

    return (

        <div>
            <div className="message-from-wo">
                <p><b>Message from Web Operations:</b> We didn't make a phone view for this page. Use your laptops for the admin panel.</p>
                <p><b>Message from Web Operations:</b> Refrain from sharing the link to this page outside Public Relations Core Team Techniche</p>
            </div>
            <div className="main1">

                <div className="left-half">

                    <div className="signup-form ml-5 mr-5 mb-10 lg:mt-10 lg:mb-10">
                        <p className='heading text-4xl'>Submit task for CAs</p>

                        <form onSubmit={handleSubmit}>
                            <input type="text" name="task" placeholder="Task" value={taskData.task} onChange={handleChange} required /><br />
                            <input type="text" name="points" placeholder="Points" value={taskData.points} onChange={handleChange} required /><br />
                            <input type="text" name="descriptions" placeholder="Description" value={taskData.descriptions} onChange={handleChange} required /><br />
                            <input type="text" name="dateOfSub" placeholder="Date of Submission" value={taskData.dateOfSub} onChange={handleChange} required /><br />
                            <button type="submit" className='signup transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500'>Submit Task</button><br />
                        </form>
                    </div>
                </div>
            </div>
            <div className="main2">
                <a href="/caprkibakchodi567796!9905/alltasks"> <button className='btn1 transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500'>All Tasks</button></a>
                <a href="/caprkibakchodi567796!9905/submissions"><button className='btn1 transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500'>All Submissions</button></a>
                
            </div>
            <div className="main2">
            <a href="/caprkibakchodi567796!9905/extrapoints"><button className='btn1 transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500'>Add Extra Points</button></a>
                <a href="/caprkibakchodi567796!9905/allusers"><button className='btn1 transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500'>View All Users</button></a></div>
        </div>
    );
}

export default CATASKSubmitForm;