import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import events from './eventsData';
import './styles.css';

const EventList = () => {
  const [hoveredEventId, setHoveredEventId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    
    if (rect) {
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;
      
      setImagePosition({ x, y });
    }
  };
  

  return (
    <div className="event-list" style={{zIndex:'98'}}>
      {events.map(event => (
          <Link 
            to={`/${event.eventname}`}
            key={event.id}
            className={`event-item ${hoveredEventId === event.id ? 'hovered' : ''}`}
            onMouseEnter={() => setHoveredEventId(event.id)}
            onMouseLeave={() => setHoveredEventId(null)}
            onMouseMove={hoveredEventId === event.id ? handleMouseMove : null}
            style={{ cursor: 'pointer' }} 
          >
          <div className="event-title-container">
            <div className={`event-title ${isMobile ? 'mobile' : ''}`}>
              {hoveredEventId === event.id
                ? <img src={event.serialb} alt={event.id} className="event-number" onError={(e) => { e.target.src = ''; }} />
                : <img src={event.serial} alt={event.id} className="event-number" onError={(e) => { e.target.src = ''; }} />
              }
              <div className="event-main-title">
                <span style={{ color: hoveredEventId === event.id ? 'blue' : 'inherit' }}>{event.title}</span>
                {isMobile && (
                  <div className="event-image-container">
                    <img src={event.image} className="event-image" />
                  </div>
                )}
              </div>
            </div>
          </div>
          {!(isMobile) && hoveredEventId === event.id && (
            <div
              className="event-image-container"
              style={{
                transform: `translate(${imagePosition.x}px, ${imagePosition.y}px)`,
                position: 'absolute',
              }}
            >
              <img
                src={event.image}
                className="event-image"
              />
            </div>
          )}

          <div className="event-subheadings">
            {event.subheadings.map((subheading, index) => (
              <div className='sub-heading' key={index}>{subheading}</div>
            ))}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default EventList;

