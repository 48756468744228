import React from 'react';
import cursor from '../assets/cursor_play.svg';
import sample_video from '../assets/AFTERMOVIE_FINAL.mp4';
import aftermovie from '../assets/aftermovie.png';

const EventVideo = ({ show }) => {
    let c = 0; // play cursor enabled

    const play_event_video = () => {
        const video = document.getElementById("event_video");
        if (c === 0) {
            c = 1; // play cursor disabled (when video is playing)
            video.play();
            video.style.cursor = "auto";
        } else {
            c = 0; // play cursor enabled
            video.pause();
            video.style.cursor = `url(${cursor}), auto`;
        }
    };

    const play_cursor = () => {
        // only if play cursor is enabled
        if (c === 0) {
            const video = document.getElementById("event_video");
            video.style.cursor = `url(${cursor}), auto`;
        }
    };

    const video_end = () => {
        const video = document.getElementById("event_video");
        video.style.cursor = `url(${cursor}), auto`;
        c = 0; // play cursor enabled
    };

    return (
        <div className='Event-video' style={{ 
            display: show ? 'flex' : 'none',
            opacity: show ? '1' : '0',
            transition: 'opacity 0.2s ease-in-out'
        }}>
            <div className="Event-video-block">
                <div className="after-movie-text">After Movie</div>
                <video 
                    src={sample_video} 
                    poster={aftermovie} 
                    onClick={play_event_video} 
                    onMouseOver={play_cursor} 
                    onEnded={video_end} 
                    id="event_video" 
                ></video>
            </div>
        </div>
    );
};

export default EventVideo;
