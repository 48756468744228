import React, { useState, useEffect } from 'react';
import "./CASignUp.css"
//import CAHeaderLogin from '../CA-HeaderLogin/ca-headerlogin';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Navbar from '../CA-Home/nav';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";


const CASignUpForm = () => {

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        institution: '',
        city: '',
        state: '',
        password: '',
        confirm_password: '',
    });

    // form input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    //  form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(baseURL + '/caauth/register', formData);
            console.log(response.data);
            navigate('/calogin');
        } catch (error) {
            if (error.response && error.response.status === 409) {
                setErrorMessage('You are already registered. Please login.');
            } else {
                setErrorMessage('Registration failed. Please check your credentials.');
            }
        }
    };

    return (

        <div className='signup-new'>
            <Navbar />
            <div className="signup-new-container">
                <p className='title'>Sign up!</p>
                <h4>Sign Up To Be A Campus Ambassador</h4>
                <form onSubmit={handleSubmit}>
                    <div className='form-option'>
                        <label for="name">Name</label>
                        <input type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} required /><br />
                    </div>
                    <div className='form-option'>
                        <label for="contact">Phone No.</label>
                        <input type="text" name="contact" placeholder="Contact Number" value={formData.contact} onChange={handleChange} required /><br />
                    </div>
                    <div className='form-option'>
                        <label for="email">Email</label>
                        <input type="text" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required /><br />
                    </div>
                    <div className='form-option'>
                        <label for="institution">Institution</label>
                        <input type="text" name="institution" placeholder="Institution" value={formData.institution} onChange={handleChange} required /><br />
                    </div>
                    <div className='form-option'>
                        <label for="city">City</label>
                        <input type="text" name="city" placeholder="City" value={formData.city} onChange={handleChange} required /><br />
                    </div>
                    <div className='form-option'>
                        <label for="state">State</label>
                        <input type="text" name="state" placeholder="State" value={formData.state} onChange={handleChange} required /><br />
                    </div>
                    <div className='form-option'>
                        <label for="password">Password</label>
                        <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required /><br />
                    </div>
                    <div className='form-option'>
                        <label for="confirm_password">Confirm Password</label>
                        <input type="password" name="confirm_password" placeholder="Confirm Password" value={formData.confirm_password} onChange={handleChange} required /><br />
                    </div>
                    {errorMessage && <p className='error-message'>{errorMessage}</p>}
                    <button type="submit" className='signup-button-white'><p>SIGNUP</p></button><br />
                    <Link to="/calogin"><button className='login-button-orange'><p>Log into Existing Account</p></button></Link>
                </form>
            </div>
        </div>

    );
}

export default CASignUpForm;