const prizes = [
    {position:'Winner' , prize:'Rs.70,000/-'},
    {position:'1st Runner Up' , prize:'Rs.40,000/-'},
    {position:'2nd Runner Up' , prize:'Rs.30,000/-'}
]
const contacts = [
    {name:'Techniche IIT Guwahati',phone:'+91 87507 91896'},
    {name:'Priya',phone:'+91 9301862804'},
    {name:'Yash',phone:'+91 7011583800'},
]
const schedule  = [
    {item:1 , date:'19th July',list:['Prelims Start']},
    {item:2 , date:'29th August',list:['Mains']},
    {item:3 , date:'',list:['']}
]

export {prizes, contacts , schedule}