import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import EventVideo from './EventVideo';
const HeroLanding = () => {
  // const ImageSequencePlayer = () => {
    const [currentFrame, setCurrentFrame] = useState(0);
    const [showVideo, setShowVideo] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const frameCount = 80; // Total number of images
    const containerRef = useRef(null);
    // Preload images
   let images=[];
    if(window.innerWidth>740)
    {
        images = Array.from({ length: frameCount }, (_, index) => (
  require(`../desktop-hero/Desktop/Desktop Main_000${String(index + 1).padStart(2, '0')}.png`)
    ));
    }
   else
   {
      images = Array.from({ length: frameCount }, (_, index) => (
       require(`../phone-hero/Phone/Phone Main_000${String(index + 1).padStart(2, '0')}.png`)
    ));
  }
  
   

    const preloadImages = (imageArray) => {
      return Promise.all(imageArray.map(src => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      }));
    };
    useEffect(() => {
     
      preloadImages(images).then(() => {
        console.log('All images preloaded');
        setIsLoading(false); 
      }).catch((error) => {
        console.error('Error preloading images:', error);
      });
    
    }, [images]);

    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.scrollY; // Current scroll position
        const maxScroll =window.innerHeight*1;// Max scrollable height
        const scrollFraction = scrollTop / maxScroll; // Fraction of the scroll position
        const newFrame = Math.min(
          Math.floor(scrollFraction * frameCount),
          frameCount - 1
        ); // Calculate the current frame based on the scroll fraction
        setCurrentFrame(newFrame);
        if (newFrame === frameCount - 1) {
          setShowVideo(true);// Show video when image sequence ends
        }
        else if (scrollTop < lastScrollTop) { // Scrolling up
          setShowVideo(false);
        }
        setLastScrollTop(scrollTop);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [frameCount, lastScrollTop]);
  return (
    <div className='Hero_landing' ref={containerRef} >
        {/* <div className='hero-all-text'>
            <span className="date-text" style={{fontSize:"2rem",alignSelf:"flex-end",color:"white",position:"absolute", transform:"translateX(50%)"}}>29th Aug - 1st Sept</span>
            <div  className='text-t24' style={{fontSize:"10rem",color:"#2D48D5"}}>
                    Techniche<span style={{position:"absolute", transform:"translateY(30%)" ,color:"#F3FF63"}}>24</span>
            </div>
            <div className='text-techniche' style={{fontSize:"1em",color:"black",fontStyle:"italic"}}>
                    The Annual Techno-Management Festival <br></br> of Indian Institute of Technolody (IIT), <br></br> Guwahati 
            </div>
        </div> */}
      {!isLoading && (
      <motion.img
         key={currentFrame}
        src={images[currentFrame]} 
        alt={`Frame ${currentFrame + 1}`} 
        // initial={{ filter: 'blur(5px)' }}
        // animate={{ filter: 'blur(0)' }}
        // exit={{ filter: 'blur(5px)' }}
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        // exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      
        style={{ 
          width: '100vw', 
          position: 'fixed', 
          top: 0, 
          left: 0 ,
          height:"100vh",
          objectFit:"cover",
          // zIndex: -1
          // backgroundImage: `url(${images[currentFrame]})`,
          // backgroundSize: 'cover',
          // backgroundPosition: 'center',
          // backgroundRepeat: 'no-repeat',
        }} 
      />
      )}
     <EventVideo show={showVideo} />
    </div>
  );
};

export default HeroLanding;