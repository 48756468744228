import React from "react";
import './Eventsghm.css';
import km21 from "../GhmAssets/21KM.png";
import km6 from "../GhmAssets/6KM.jpeg";
import {Link} from "react-router-dom";
import Navbar from "./Navbar.js";
import facebooklogo from "../GhmAssets/Group 783.png";
import linkedinlogo from "../GhmAssets/Group 785.png";
import twitterlogo from "../GhmAssets/Group 786.png";
import youtubelogo from "../GhmAssets/Group 788.png";
import instalogo from "../GhmAssets/Group 35057.png";
import eventr1 from "../GhmAssets/eventr1.png";
import eventr2 from "../GhmAssets/Ribbonevent.png";
import eventr3 from "../GhmAssets/eventr3.png";

function EventGhm() {
    return (<>
      <Navbar/>
      <div className="eventribbon er-1">
        <img src={eventr1} alt="" />
      </div>
      <div className="eventribbon er-2">
        <img src={eventr2} alt="" />
      </div>
      <div className="eventribbon er-3">
        <img src={eventr3} alt="" />
      </div>
      <div className="body-event-details">
        <div className='event-details-header-wrapper'>
          <p>EVENT DETAILS</p>
        </div>
  
        <div className='event-details-categories-wrapper'>
          <div className='event-details-categories-heading'><p>CATEGORIES</p></div>
  
  
          <div className='event-details-categories-content-wrapper' id='event-details-categories-content-1'>
            <div className='event-details-categories-content' id='event-details-categories-content-1'>
              <div className='event-details-categories-image'>
                <a href="https://www.google.com/maps/dir/Ganesh+Pandit+Hotel,+4Q62%2BQPJ,+Lokhra,+Guwahati,+Assam+781040/26.1117241,91.7581493/26.1115099,91.7667921/26.1113267,91.7772686/D+P.+HARDWARE,+NH37,+Lalmati,+Guwahati,+Assam/Borah+Hotel,+Durga+Mandir+Road,+Bhetapara,+Hirakud,+Odisha/@26.1111258,91.7508339,14.11z/data=!4m23!4m22!1m5!1m1!1s0x375a5dae35190db7:0xaeac82169519ee1c!2m2!1d91.7517774!2d26.1118931!1m0!1m0!1m0!1m5!1m1!1s0x375a5f909d0b2479:0xba89c71f3842a318!2m2!1d91.7840689!2d26.1116124!1m5!1m1!1s0x375a5f003aa21443:0x9aaf3f552deacf20!2m2!1d91.7878284!2d26.1115608!3e2?entry=ttu"><img src= {km21}/>
                </a>
              </div>
              <div className='event-details-categories-head'>
                <p className='event-details-categories-distance' >21Km</p>
                <p className='event-details-categories-name' >Glory Run</p>
              </div>
              <div className='event-details-categories-detail'>
                This is a 21 kilometre half marathon, the first of its kind in North-East India and the flagship event of the day. The race is open to everyone above the age of 14.
              </div>
            </div>
  
            <div className='event-details-categories-content' id='event-details-categories-content-2'>
              <div className='event-details-categories-image'>
                <a href="https://maps.app.goo.gl/qmNowQvwqAFFVrQU6"><img src= {km6}/>
                </a>
              </div>
              <div className='event-details-categories-head'>
                <p className='event-details-categories-distance' >6KM</p>
                <p className='event-details-categories-name' >Spirit Run</p>
              </div>
              <div className='event-details-categories-detail'>
                This is a 6 km run specifically designed for the citizens of Guwahati. The run is open to participants of all ages.
              </div>
            </div>
          </div>
        </div>
  
        <div className="page-3">
          {/* <div className="set1"> */}
          
          <div className="blocks">
            <p class="infoheaders" id="prize_header">Prize Money</p>
            <div id="prize_content">
              <p className='prize-para'>The total cash prize money for all the events in 2024 is <b>INR 2.5 Lacs.</b></p>
              <br />
              <p className='prize-para'> Certificates will be given to all the participants who will complete the Glory Run, including their timing. Cash awards will be given to the top eight from both the Men's and Women's categories. </p>
              <br />
              {/* <p className='prize-para'>The prize money for the 2024 edition will also be on similar lines.</p> */}
            </div>
  
          </div>
  
     
  
          { /* </div> */}
  
          {/* <div className="set2"> */}
          <div className="blocks">
            <p class="infoheaders" id="Facilities_header">Facilities</p>
  
            <p class="sub_headers"><b>Medical Facilities</b></p>
            <p className="sub_headers-para"> We will have enough ambulances with us for any medical problem. The ambulance will have all the essential medical facilities to avoid any untoward incidents. </p>
  
            <p class="sub_headers"><b>Back-up vehicles</b></p>
            <p className="sub_headers-para">If any of the participants gets exhausted, we provide vehicles for them to return to the destination. </p>
  
            <p class="sub_headers"><b>Refreshments</b></p>
            <p className="sub_headers-para">The refreshments will be provided at the final destination for every participant. </p>
  
            <p class="sub_headers"><b>Amenities</b></p>
            <p className="sub_headers-para"> We provide drinking water, toilets and all other facilities both at the stadium and the road ahead.</p>
          </div>
          
          
          <div className="blocks" id="instruction_block">
            <p class="infoheaders" id="Instructions_header">Instructions</p>
            <p className="sub_headers-para"> Both the runs will start and end at <b> Karmabir Nabin Chandra Bordoloi  Stadium Indoor Stadium.</b></p>
            <p className="sub_headers-para">So, as you cross the finish line, please keep moving ahead towards the Karmabir Nabin Chandra Bordoloi Indoor Stadium - do not wait/shop near the finish area as you will come in the way of your fellow runners who will be crossing the finish line. </p>
            <p className="sub_headers-para">Refreshment packets will be available for all participants after the run. To benefit your fellow runners and general hygiene, we request you to throw peels and packaging in the dustbin provided near the refreshment counters.  </p>
  
            <p className="sub_headers-para">As you cross the finish line, do not obscure your running number (bib) from your chest, the officials, the television, and the photographers will find it difficult to identify you.  </p>
  
            <p class="sub_headers"><b>For Slow Runners</b></p>
            <p className="sub_headers-para">Guwahati Half Marathon will have priority on the Guwahati roads till 9.30 am on race day; if you cannot finish the run by this time and are still determined to reach the finish, please keep the footpath and stay off the roads as vehicular traffic will be released. </p>
          </div>
  
          <div className="blocks" id='reach-block'>
            <p class="infoheaders" id="Reach_header">Reach Guwahati</p>
            <p class="sub_headers"><b>Guwahati by Air</b> </p>
            <p className="sub_headers-para">Guwahati Airport is the gateway to the North-East is well connected to many major cities in India, including Delhi, Mumbai, Chennai, Kolkata, and Imphal. Delhi and Kolkata are the international airports close to Guwahati; both are nearly 1100 km from Guwahati. International travellers can connect to Guwahati through Kolkata and Delhi.</p>
  
            <p class="sub_headers"><b>Guwahati By Train</b></p>
            <p className="sub_headers-para">The Paltan Bazaar Railway Station in Guwahati is a major railway junction on the North-E Frontier Railway. Guwahati is well connected with all the major railheads of India. For Train Schedules, visit Indian Railways.   </p>
          </div>
  
        </div>
        
  
  
        <div className="page-4">
  
          <ol className="rules_list" type='1'>
            <div class="infoheaders" id="rules_header">Rules & Regulations</div><br/><li className="sub_headers-para">You can participate only in the race category for which you apply and for which your entry has been confirmed. Your entry and <b>GHM Runners ID</b> bib are non-transferable to any other person. </li>
            <li className="sub_headers-para">The prize money is applicable for both Glory & Spirit runs. In the Spirit Run and Glory Run, prizes will be given to men and women runners.</li>
            <li className="sub_headers-para">Wheeled vehicles or animals are not allowed on the course. Anyone using such means will be disqualified from the race.  </li>
            <li className="sub_headers-para">Participants wearing the current 2024 runners id (bib) will only be allowed to run. </li>
            <li className="sub_headers-para">Baggage counters are available. For security reasons, the participants are advised to come without any baggage. Organisers will NOT be responsible for any loss of baggage or belongings. </li>
            <li className="sub_headers-para">We will have enough ambulances with us for any medical problem. Ambulances have all the essential medical facilities to avoid any damage. If any participant gets exhausted, we provide vehicles to reach their final destination.</li>
            <li className="sub_headers-para">The decision of the organiser will be final and binding. </li>
  
          </ol>
        </div>
        
            <div className="registernowlink">
               <Link to="/ghm/register">REGISTER <i>NOW</i></Link>
            </div>
            <div className="footer-ghm">
        <div className="vector">
          <a
            href="https://www.instagram.com/techniche_iitguwahati?igsh=YTh3NWRmbmpwbTRu"
            className="instalogosghm"
          >
            <img src={instalogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a
            href="https://www.facebook.com/techniche.iitguwahati/"
            className="facebooklogosghm"
          >
            <img src={facebooklogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a
            href="https://in.linkedin.com/company/techniche-iitg"
            className="linkedinlogosghm"
          >
            <img src={linkedinlogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a href="" className="twitterlogosghm">
            <img src={twitterlogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a
            href="https://www.youtube.com/user/technicheiitg"
            className="youtubelogosghm"
          >
            {" "}
            <img src={youtubelogo} style={{ width: "100%", height: "100%" }} />
          </a>
        </div>
        <div className="lowerfooter">
          <div className="leftside">
            <div className="seeyouatthe">
              <p className="startingline">
                See you at the <i>starting line</i>
              </p>
            </div>
            <div className="writetous">
              <h1 id="writeto">Write to Us</h1>
              <a href="" id="mailtechniche">
                ✉️info@technicheiitg.in
              </a>
            </div>
          </div>
          <div className="rightside">
            <div className="quicklinksblock">
              <h2 className="quicklinksheading">Quick Links</h2>
              <p className="linksghm">
                <a
                  href="https://techniche.org/ghm/register"
                  className="linkitems"
                >
                  Register Now
                </a>
              </p>
              <p className="linksghm">
                <a href="https://techniche.org/ghm/about" className="linkitems">
                  About GHM
                </a>
              </p>
              <p className="linksghm">
                <a href="https://techniche.org/ghm/eventdetails" className="linkitems">
                  Event Details
                </a>
              </p>
            </div>
            <div className="contactusghm">
              <h2 className="quicklinksheading">Contact Us</h2>
              <p className="contact-details">Shikhar Mishra</p>
              <a className="contact-number" href="tel:+918690624922">
                +91 8690624922
              </a>
              <p className="contact-details">Divyanshu Tiwari</p>
              <a className="contact-number" href="tel:+918750791896">
                +91 8750791896
              </a>
              <p className="contact-details">Rachit Shah</p>
              <a className="contact-number" href="tel:+917389007970">
                +91 7389007970
              </a>
            </div>
          </div>
        </div>
      </div>
        
  
      </div>

    </>)
  }
  
  export default EventGhm;