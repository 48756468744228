import i1999 from '../../../assets/1999.png'
import i2009 from '../../../assets/2009.png'
import i2010 from '../../../assets/2010.png'
import i2017 from '../../../assets/2017.png'
import i2022 from '../../../assets/2022.png'
import i2023 from '../../../assets/2023.png'
export const years = [
    {"id":0,"year":1999,"text":'Journey of Techniche begins in 1999.',"url":i1999},
    {"id":1,"year":2009,"text":'First edition of Guwahati Half Marathon (GHM) is commenced.',"url":i2009},
    {"id":2,"year":2010,"text":"Technothlon has achieved global reach, emerging as India's premier logic exam for students.","url":i2010},
    {"id":3,"year":2017,"text":"5 neighbouring villages were adopted under the Pragati Initiatives.","url":i2017},
    {"id":4,"year":2023,"text":"Techniche successfully completed 25 years, marking a silver milestone.","url":i2023}
  ]