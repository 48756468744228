const prizes = [
    {position:'Winner' , prize:'Rs.1,30,000/-'},
    {position:'1st Runner Up' , prize:'Rs.1,00,000/-'},
    {position:'2nd Runner Up' , prize:'Rs.70,000/-'}
]
const contacts = [
    {name:'Techniche IIT Guwahati',phone:'+91 87507 91896'},
    {name:'Naman Karwa',phone:'+91 93777 80309'},
    {name:'Khushi Aggarwal',phone:'+91 81468 43309'},
]
const schedule  = [
    {item:1 , date:'16th August',list:['Registration Deadline']},
    {item:2 , date:'30th August',list:['Round 1 starts']},
    {item:3 , date:'',list:['']}
]

export {prizes, contacts , schedule}