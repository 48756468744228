import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './AddTeamRW.css';
import { useAuth } from '../../../../services/authContext';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const AddTeamRW = () => {
  const { sanitizedHash } = useParams();
  const [responseMessage, setResponseMessage] = useState('');
  const { isLoggedInMain, userDataMain, setUserDataMain, loginmain, logoutmain } = useAuth(); 
  const [teamMemberIDs, setTeamMemberIDs] = useState({
    member1: '',
    member2: '',
    member3: '',
    member4: '',
    member5: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeamMemberIDs(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (memberKey) => {
    try {
      const response = await axios.post(baseURL + '/robowars/addteam', {
        email: userDataMain.email,
        t_id: teamMemberIDs[memberKey],
        member: memberKey
      });
      setResponseMessage(response.data.message);
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            setResponseMessage(error.response.data.message);
          } else {
            setResponseMessage('Error submitting T_ID. Please try again.');
          }
    }
  };

  return (
    <div className="addteam-rw-container">
      <div className='addteam-rw-instruction-container'>
        <div className='addteam-rw-instruction-contents'><p>Step 1: Make sure all your teammates have created a techniche account at <a href="https://techniche.org/signup">techniche.org/signup</a></p></div>
        <div className='addteam-rw-instruction-contents'><p>Step 2: Get their Techniche ID (T_ID) as shown on their dashboard</p></div>
        {responseMessage && <div className='response-message' style={{color:"#F53053"}}>{responseMessage}</div>}
      </div>
      <div className='view-team-button'>
            <Link to={`/dashboard/${sanitizedHash}/rwviewteam`}><button className='addteam-submit-button bg-gradient-to-r from-blue-500 to-violet-900'   type='submit'>See Team Details</button></Link>
      </div>
      <div className='addteam-rw-add-container'>
        <div className='addteam-rw-add'>
          <div className="age" style={{ alignItems: "center", width: "50vw" }}>
            <label htmlFor="member1">Team member 1 T_ID</label>
            <input type="text" id='member1' style={{ border: '1px solid', borderColor: '#FFFFFF', borderRadius: "50px", paddingLeft:"20px", color: "black" }} name="member1" placeholder="Enter Team member 1 T_ID" onChange={handleChange} value={teamMemberIDs.member1} required />
          </div>
          <div>
            <button onClick={() => handleSubmit('member1')} className='addteam-submit-button bg-gradient-to-r from-blue-500 to-violet-900'  type='submit'>Submit</button>
          </div>
        </div>
        <div className='addteam-rw-add'>
          <div className="age" style={{ alignItems: "center" }}>
            <label htmlFor="member2">Team member 2 T_ID</label>
            <input type="text" id='member2' style={{ border: '1px solid', borderColor: '#FFFFFF', borderRadius: "50px", paddingLeft:"20px", color: "black" }} name="member2" placeholder="Enter Team member 2 T_ID" onChange={handleChange} value={teamMemberIDs.member2 } required />
          </div>
          <div>
            <button onClick={() => handleSubmit('member2')} className='addteam-submit-button bg-gradient-to-r from-blue-500 to-violet-900' type='submit'>Submit</button>
          </div>
        </div>
        <div className='addteam-rw-add'>
          <div className="age" style={{ alignItems: "center" }}>
            <label htmlFor="member3">Team member 3 T_ID</label>
            <input type="text" id='member3' style={{ border: '1px solid', borderColor: '#FFFFFF', borderRadius: "50px", paddingLeft:"20px", color: "black" }} name="member3" placeholder="Enter Team member 3 T_ID" onChange={handleChange} value={teamMemberIDs.member3} required />
          </div>
          <div>
            <button onClick={() => handleSubmit('member3')} className='addteam-submit-button bg-gradient-to-r from-blue-500 to-violet-900'  type='submit'>Submit</button>
          </div>
        </div>
        <div className='addteam-rw-add'>
          <div className="age" style={{ alignItems: "center" }}>
            <label htmlFor="member4">Team member 4 T_ID</label>
            <input type="text" id='member4' style={{ border: '1px solid', borderColor: '#FFFFFF', borderRadius: "50px", paddingLeft:"20px", color: "black" }} name="member4" placeholder="Enter Team member 4 T_ID" onChange={handleChange} value={teamMemberIDs.member4} required />
          </div>
          <div>
            <button onClick={() => handleSubmit('member4')} className='addteam-submit-button bg-gradient-to-r from-blue-500 to-violet-900'  type='submit'>Submit</button>
          </div>
        </div>
        <div className='addteam-rw-add'>
          <div className="age" style={{ alignItems: "center" }}>
            <label htmlFor="member5">Team member 5 T_ID</label>
            <input type="text" id='member5' style={{ border: '1px solid', borderColor: '#FFFFFF', borderRadius: "50px", paddingLeft:"20px", color: "black" }} name="member5" placeholder="Enter Team member 5 T_ID" onChange={handleChange} value={teamMemberIDs.member5} required />
          </div>
          <div>
            <button onClick={() => handleSubmit('member5')} className='addteam-submit-button bg-gradient-to-r from-blue-500 to-violet-900'  type='submit'>Submit</button>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default AddTeamRW;
