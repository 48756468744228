import React from 'react'

const Journey_phone = () => {
  return (
    <div className='Journey_phone'>
      
    </div>
  )
}

export default Journey_phone
