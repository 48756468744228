import React,{useEffect , useState} from 'react';
import './Workshop.css';
import { Link } from 'react-router-dom';
import Tile from '../LongTile/LongTile';
import LeftButton from '../../../assets/arrowleft.png';
import RightButton from '../../../assets/arrowright.png';
import BlockchainImg from '../../../assets/blockchain.png';
import FullstackImg from '../../../assets/FulStack2.png';
import PromptEngImg from '../../../assets/PromptEng.png';
import CyberSeqImg from '../../../assets/CyberSeq2.png';
import Java from '../../../assets/Java2.png';
import GenAI from '../../../assets/aiml.png';
import Footer from '../../../Footer/Footer';
import Marquee from '../Marquee/Marquee';
import CursorAnimation from '../../../CursorAnimation';

const Workshops = () => {
    const BlockchainItems = ['Blockchain', 'Blockchain', 'Blockchain', 'Blockchain','Blockchain', 'Blockchain', 'Blockchain', 'Blockchain',' Blockchain', 'Blockchain', 'Blockchain', 'Blockchain','Blockchain', 'Blockchain', 'Blockchain', 'Blockchain','Blockchain', 'Blockchain', 'Blockchain', 'Blockchain','Blockchain', 'Blockchain', 'Blockchain', 'Blockchain',' Blockchain', 'Blockchain', 'Blockchain', 'Blockchain','Blockchain', 'Blockchain', 'Blockchain', 'Blockchain'];
    const FullStackItems = ['Full Stack', 'Full Stack', 'Full Stack', 'Full Stack','Full Stack', 'Full Stack', 'Full Stack', 'Full Stack','Full Stack', 'Full Stack', 'Full Stack', 'Full Stack','Full Stack', 'Full Stack', 'Full Stack', 'Full Stack','Full Stack', 'Full Stack', 'Full Stack', 'Full Stack','Full Stack', 'Full Stack', 'Full Stack', 'Full Stack','Full Stack', 'Full Stack', 'Full Stack', 'Full Stack','Full Stack', 'Full Stack', 'Full Stack', 'Full Stack'];
    const PromptEngineeringItems = ['Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering','Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering','Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering','Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering','Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering','Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering','Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering','Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering', 'Prompt Engineering'];
    const GenAIItems = ['AI ML', 'AI ML', 'AI ML', 'AI ML','AI ML', 'AI ML', 'AI ML', 'AI ML','AI ML', 'AI ML', 'AI ML', 'AI ML','AI ML', 'AI ML', 'AI ML', 'AI ML','AI ML', 'AI ML', 'AI ML', 'AI ML','AI ML', 'AI ML', 'AI ML', 'AI ML','AI ML', 'AI ML', 'AI ML', 'AI ML','AI ML', 'AI ML', 'AI ML', 'AI ML'];
    const CyberSecurityItems = ['Cyber Security', 'Cyber Security', 'Cyber Security', 'Cyber Security','Cyber Security', 'Cyber Security', 'Cyber Security', 'Cyber Security','Cyber Security', 'Cyber Security', 'Cyber Security', 'Cyber Security','Cyber Security', 'Cyber Security', 'Cyber Security', 'Cyber Security','Cyber Security', 'Cyber Security', 'Cyber Security', 'Cyber Security','Cyber Security', 'Cyber Security', 'Cyber Security', 'Cyber Security','Cyber Security', 'Cyber Security', 'Cyber Security', 'Cyber Security','Cyber Security', 'Cyber Security', 'Cyber Security', 'Cyber Security'];
    const JavaItems = ['AWS', 'AWS', 'AWS', 'AWS','AWS', 'AWS', 'AWS', 'AWS','AWS', 'AWS', 'AWS', 'AWS','AWS', 'AWS', 'AWS', 'AWS','AWS', 'AWS', 'AWS', 'AWS','AWS', 'AWS', 'AWS', 'AWS','AWS', 'AWS', 'AWS', 'AWS','AWS', 'AWS', 'AWS', 'AWS'];
      
    return ( 
        <div className="Wrapper-Workshops">
            <CursorAnimation />
            <div className="EventNav" style={{display: 'flex', justifyContent: 'space-around' ,width: '100vw',height: '12vh', overflow: 'hidden', paddingTop: '1vw', paddingBottom: '1vw', flexWrap:'wrap', zIndex:'95'}}>

                <div className="event-sidenav"  style={{ color: '#808182' , marginRight: '2%', zIndex:'97', cursor:'pointer'}}>
                    <Link to="/competitions"className="event-sidenav-module" style={{fontFamily: 'That_New_Pixel', cursor:'pointer'}}>Competions</Link>
                </div>
                <Link to={`/competitions`} style={{alignContent: 'center', alignItems: 'center', zIndex:'95'}}><button className='event-navbutton' style={{alignContent: 'center', alignItems: 'center'}}><img src={LeftButton}  style={{cursor:'pointer'}}/></button></Link>

                <div className="event-nav" style={{marginLeft: 'auto', marginRight: 'auto', display: 'inline', zIndex:'95'}}>
                    <i  style={{fontFamily: 'That_New_Pixel', color: '#2D48D5'}}>Workshops</i>
                </div>

                <Link to={`/exhibitions`} style={{alignContent: 'center', alignItems: 'center', zIndex:'95'}}><button className='event-navbutton' style={{alignContent: 'center', alignItems: 'center'}}><img src={RightButton}   style={{cursor:'pointer'}}/></button></Link>
                <div className="event-sidenav"  style={{fontFamily: 'That_New_Pixel', color: '#808182', marginLeft: '2%', zIndex:'95'}}>
                    <Link to="/exhibitions" className="event-sidenav-module" style={{fontFamily: 'That_New_Pixel', cursor:'pointer'}}>Exhibitions</Link>
                </div>

            </div>
            {/* Event Pages Switching Navbar */}

            <Marquee bgColor="#F3FF63" letterColor="#2D48D5" text={BlockchainItems}/>
            <Link to="https://unstop.com/workshops-webinars/blockchain-techniche-iit-guwahati-1105480"><Tile title="Blockchain" img={BlockchainImg} color="#2D48D5" link="https://unstop.com/workshops-webinars/blockchain-techniche-iit-guwahati-1105480"/></Link>

            <Marquee bgColor="#2D48D5" letterColor="#F7F8FF" text={FullStackItems}/>
            <Link to="https://unstop.com/workshops-webinars/full-stack-engineering-workshop-techniche-iit-guwahati-1106157"><Tile title="Fullstack" img={FullstackImg} color='white' link="https://unstop.com/workshops-webinars/full-stack-engineering-workshop-techniche-iit-guwahati-1106157"/></Link>

            <Marquee bgColor="#F3FF63" letterColor="#2D48D5" text={PromptEngineeringItems}/>
            <Link to="https://unstop.com/workshops-webinars/prompt-engineering-techniche-iit-guwahati-1105340"><Tile title="Prompt Engineering" img={PromptEngImg} color="#2D48D5" link="https://unstop.com/workshops-webinars/prompt-engineering-techniche-iit-guwahati-110"/></Link>

            <Marquee bgColor="#2D48D5" letterColor="#F7F8FF" text={GenAIItems}/>
            <Link to="https://unstop.com/workshops-webinars/ai-ml-artificial-intelligence-and-machine-learning-techniche-iit-guwahati-1105154"><Tile title="AI ML"  color="#2D48D5"img={GenAI} link="https://unstop.com/workshops-webinars/ai-ml-artificial-intelligence-and-machine-learning-techniche-iit-guwahati-1105154"/></Link>

            <Marquee bgColor="#F3FF63" letterColor="#2D48D5" text={CyberSecurityItems}/>
            <Link to="https://unstop.com/workshops-webinars/cybersecurity-and-ethical-hacking-techniche-iit-guwahati-1106021"><Tile title="Cyber Security" img={CyberSeqImg} color='white' link="https://unstop.com/workshops-webinars/cybersecurity-and-ethical-hacking-techniche-iit-guwahati-1106021"/></Link>

            <Marquee bgColor="#2D48D5" letterColor="#F7F8FF" text={JavaItems}/>
            <Link to=""><Tile title="AWS Container Services" img={Java} /></Link>

            {/* footer*/}

            <div className="footer">
                <Footer />
            </div>

        </div>
        
     );
};

 
export default Workshops;