import HomeAbout from "./homeAbout/Home"
import ReachAbout from "./reachAbout/Reach"
import YearsAbout from "./yearsAbout/Years"
import footerPixel from '../../assets/footerPixel.png'
import './About.css'
import FramesAbout from "./framesAbout/Frames"
import TeamAbout from "./teamAbout/Team"
import Footer from "../../Footer/Footer"
import myCursor from './../../assets/samyak.png'

const AboutPage=()=>{
    return(
        <div className="about-outer-wrapper" >
        <HomeAbout/>
        <ReachAbout/>
        <YearsAbout/>
        <div className="gap"></div>
        <div className="footer-pixel-top-2" style={{}}>
                <img src={footerPixel} alt='' style={{width:'100vw'}}/>
        </div>
        <FramesAbout/>
        <TeamAbout/>
        <Footer/>
        </div>
    )
}

export default AboutPage