import footerLogo from '../assets/footer-logo.png'
import footerPixel from '../assets/footerPixel.png'
import footerBannerLogo from '../assets/footerBannerLogo.png'
import { data } from './data'
import './style.css'
import SocialIcon from './social'
import { Link } from 'react-router-dom';
import { CIcon } from '@coreui/icons-react';
import { cilExternalLink} from '@coreui/icons';


const Footer = ()=>{
   return(
       <div className="footer-wrapper" style={{position:'relative',display:'flex'}}>
           <div className="footer-pixel-top" style={{position:'absolute'}}>
               <img src={footerPixel} alt='' style={{width:'100vw'}}/>
           </div>
           <div className='main-footer-container' style={{height:'100%'}}>
               <div className='main-footer-inner-container' style={{display:'flex',flexDirection:'column',justifyContent:'flex-start'}}>
                   <div className='main-footer-heading' style={{fontWeight:'200', height: '15vh'}}>
                       Experience one of <span style={{fontStyle:'italic',color:'#F3FF63'}}>India's Best Techno-Management Fest!</span>
                   </div>
                   <Link to="/signup" style={{height: '12vh'}}>
                       <div className='footer-btn-wrapper' style={{backgroundColor:'black',width:'max-content', padding:'0.05vh', display:'flex', justifyContent:'center', alignItems:'center', }}>
                           <span className='footer-btn'>Sign Up</span>
                       </div>
                   </Link >
                       <div className='footer-aboutus' style={{fontWeight:'200', height: '80px'}}>
                           Discover our journey?
                           <div className="know-more-about-us" style={{color:'#F3FF63', fontFamily:'That_New_Pixel'}}>
                               <div className="know-more-about-us-inner" style={{fontFamily:"That_New_Pixel", display:'flex'}}>Know more </div>
                               <div>
                                   <Link to="/about" style={{fontFamily:"That_New_Pixel", display:'flex', fontStyle:'italic',textDecoration:'underline', color:'#F3FF63'}} className='aboutus-link'>
                                       About Us
                                      <CIcon className="AboutUsicon" style={{ '--ci-primary-color':'#F3FF63'}}icon={cilExternalLink} />
                                   </Link>
                               </div>
                           </div>
                       </div>
                   <div className='footer-social-wrapper' style={{height:'50%', width:'45vw'}}>
                       <div className='footer-social-inner-wrapper' style={{display:'flex',justifyContent:'space-around'}}>
                           <div className='email-contact-footer'>Contact us <p className='email-id-footer' style={{fontStyle:'italic',fontWeight:'100'}}>techniche@iitg.ac.in</p></div>
                       </div>
                       <div className="socials-icons-full"style={{display:'flex',justifyContent:'space-between', zIndex:'100'}}>
                           {
                               data.map((icon,index)=>{
                                   return (
                                      <div key={index} style={{display:'flex',alignItems:'center'}}>
                                       <SocialIcon icon={icon}/>
                                       </div>
                                   )
                               })
                           }
                       </div>
                   </div>
               </div>
           </div>
           <div className='footer-logo-container'style={{width:'50%',display:'flex',justifyContent:'right',alignItems:'flex-start',}}>
               <img className='footer-logo-img'  src={footerLogo} alt=''/>
           </div>
           <div className='footer-banner-wrapper' style={{padding:'5px 0px',height:'fit-content',width:'100vw',overflowX:'hidden'}}>
               <div className='footer-banner-inner' style={{display:'flex',color:'black',fontWeight:'100',width:'max-content'}}>
               Developed by Techniche Web Operations
               <img src={footerBannerLogo} alt='' style={{margin:'0px 40px'}}/>
               Designed by Techniche Creatives
               <img src={footerBannerLogo} alt='' style={{margin:'0px 40px'}}/>
               Developed by Techniche Web Operations
               <img src={footerBannerLogo} alt='' style={{margin:'0px 40px'}}/>
               Designed by Techniche Creatives
               <img src={footerBannerLogo} alt='' style={{margin:'0px 40px'}}/>
               Developed by Techniche Web Operations
               <img src={footerBannerLogo} alt='' style={{margin:'0px 40px'}}/>
               Designed by Techniche Creatives
               <img src={footerBannerLogo} alt='' style={{margin:'0px 40px'}}/>
               Developed by Techniche Web Operations
               <img src={footerBannerLogo} alt='' style={{margin:'0px 40px'}}/>
               Designed by Techniche Creatives
               <img src={footerBannerLogo} alt='' style={{margin:'0px 40px'}}/>
               </div>
           </div>
       </div>
   )
}
export default Footer

