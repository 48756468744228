import React from "react";
import './Small-Image.css';

const SmallImage = (props) => {
    return (
        <div className="SmallImageWrapper">
            <div className="SmallImageContainer">
                <img className="SmallImageImage" src={props.img} alt="Blockchain" />
            </div>
        </div>
    );
}

export default SmallImage;
