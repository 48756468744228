import React from "react";
import { Link } from "react-router-dom";
import './SpeakersTile.css';
// import { CIcon } from '@coreui/icons-react';
// import { cilExternalLink} from '@coreui/icons';

//a note from Srinjoy - It was showing some error that the @coreui/icons does not exist although i npm i it. 
// I saw that the error is due to this file but the cause error was not in use. I commented it out for now.

const Tile = (props) => {
    return (
        <div className="SpeakerContainerOutter">
            <div className="SpeakersTileWrapper">
                <div className="SpeakersTileContainer">
                   <img className="SpeakersTileImage" src={props.img} alt="Blockchain" />
                </div>
           </div>
           <div style={{marginTop:'2vh'}}>
            <div className="SpeakersTileText" style={{textAlign:'center', fontSize:'1.1rem', fontWeight:'400'}}>{props.Name}</div>
            <div className="SpeakersTileText" style={{textAlign:'center', fontStyle:'italic', fontSize:'1rem', fontWeight:'100'}}>{props.About}</div>
            <div className="SpeakersTileText" style={{textAlign:'center', fontStyle:'italic', fontSize:'1rem', fontWeight:'100'}}>{props.About2}</div>
           </div>
        </div>
    );
}

export default Tile;
