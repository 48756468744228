import  AutoImg from "../../../../assets/autoexpo.png"
import ArrowLeft from "../../../../assets/ArrowLeftEvent.png"
import { contacts } from './data'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import CursorAnimation from '../../../../CursorAnimation'
import SmallImage from "../../Small-Image/Small-Image";
import Footer from "../../../../Footer/Footer";

const AutoExpo = ()=>{

    const [current , setCurrent ] = useState(0)
    const overviewRef = useRef(null)
    const contactRef = useRef(null)
    
    useEffect(()=>{

        const callback = ()=>{
            [overviewRef.current  , contactRef.current].map((ref)=>{
                const rect = ref.getBoundingClientRect()
                if((100*rect.y/window.innerHeight)<30 && (100*rect.y/window.innerHeight)>0) setCurrent(ref.id)
            })
        }

        window.addEventListener('scroll' , callback)
        return ()=>{window.removeEventListener('scroll', callback)}
    },[])
    

    return(
        <>
        <div className="singularevent-outer-wrapper">
            <CursorAnimation/>
            <div className="singularevent-title">
                <div className='go-left-arrow'>
                  <Link to="/exhibitions"><img src={ArrowLeft}/></Link>
                </div>
                Auto Expo
                <div className='go-left-arrow'>
                  
                </div>
            </div>
            <div className="singularevent-inner-wrapper">
                <div className="singularevent-navbar">
                    <ul style={{display:'flex' , listStyle:'none',backgroundColor:'#F3FF63', justifyContent:'space-evenly'}}>
                        <li onClick={()=>{setCurrent('singularevent-overview')}}>
                            <a style={current==='singularevent-overview'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#'>Overview</a>
                        </li>

                        <li onClick={()=>{setCurrent('singularevent-contact')}}>
                            <a style={current==='singularevent-contact'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#singularevent-contact'>Contact</a>
                        </li>
                      
                    </ul>
                </div>
                <div className="singularevent-content-outer">
                    <div className="singularevent-content-border clip-bg" style={{backgroundColor:'black',padding:'0px 4px 4px 4px'}}>
                        <div className="singularevent-content-inner clip-bg">
                            <div ref={overviewRef} id="singularevent-overview">
                                <div className="singularevent2-content-wrapper">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" "/>Overview
                                    </div>
                                    <div className="singularevent-content-details">
                                        <div id='events-scroll-tracker'></div> 
                                        Motorcycling clubs, solo riders and car lovers take part in this event. During this event, drivers of cars and motorcycles do a roadshow on campus.
                                        The event fosters a sense of community among enthusiasts, providing a platform for riders and car lovers to display their passion and craftsmanship. It also promotes safe and responsible riding practices, while celebrating the artistry and culture of motorcycling and car customization.
                                    </div>  
                                      
                                </div>    
                            </div>
                            <div className='singularevent2-img-wrapper'>
                                  <div className='clip-class' style={{padding:'2px',backgroundColor:'black'}}>                               
                                      <img src={AutoImg}  alt=""/>
                                  </div>
                            </div>
                            <div ref={contactRef} id="singularevent-contact">
                                <div className="singularevent-content-heading">
                                    <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" "/>Contact
                                </div>
                                <div className="singularevent-content-details">
                                    {contacts.map((item)=>{
                                        return(
                                            <div style={{margin:'10px'}}>
                                                <p style={{fontWeight:'500', fontSize:'1.2rem', fontFamily:"FK_Grotesk"}}>{item.name}</p>
                                                <p style={{fontWeight:'100', fontSize:'1rem', fontFamily:"FK_Grotesk"}}>{item.phone}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}
export default AutoExpo