import React from 'react'
import robowar from '../assets/robowar.jpeg';
import { useEffect } from 'react';
import skysprintImg from '../assets/wsp.png';
import skysprintImgp from '../assets/wsp-pixel.png';
import aquawarImg from '../assets/aqua.png';
import aquawarImgp from '../assets/aqua-pixel.png';
import escaladeImg from '../assets/esc.png';
import escaladeImgp from '../assets/esc-pixel.png';
import lectureseriesImgp from '../assets/ls-pixel.png';
import lectureseriesImg from '../assets/ls.png';
import microImg from '../assets/tt.png';
import microImgp from '../assets/tt-pixel.png';
import nexusImgp from '../assets/nex-pixel.png';
import nexusImg from '../assets/nex.png';
import robowarsImgp from '../assets/robo-pixel.png';
import robowarsImg from '../assets/robo.png';
import techexpoImgp from '../assets/techex-pixel.png';
import techexpoImg from '../assets/techex.png';
import valorantImg from '../assets/valo.png';
import valorantImgp from '../assets/valo-pixel.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
const Exciting_events = () => {
  let j = 1;

  function slideshow() {
    const cards = document.getElementsByClassName("Exciting-Event-card");
    const totalCards = cards.length;
    const slideWidth = 22.5; // Adjust if needed

    if (totalCards === 0) return;

    for (let i = 0; i < totalCards; i++) {
      const rightPosition = i >= j ? j * slideWidth : (j - totalCards) * slideWidth;
      cards[i].style.right = `${rightPosition}vw`;

      const isHighlighted = Math.abs((5 - (i + 1)) * slideWidth - (-1 * rightPosition)) < 0.005;
      if (isHighlighted) {
        cards[i].children[0].id = "Exciting-Event-img-3";
        cards[i].children[0].children[1].id = "Exciting-Event-img-3-img";
        cards[i].children[0].children[0].style.display = "none";
        cards[i].children[0].children[2].id = "Exciting-Event-img-3-name";
      } else {
        cards[i].children[0].id = "";
        cards[i].children[0].children[1].id = "";
        cards[i].children[0].children[0].style.display = "block";
        cards[i].children[0].children[2].id = "";
      }
    }

    j = j === totalCards ? 0 : j + 1;
  }

  useEffect(() => {
    const intervalId = setInterval(slideshow, 3000);

    return () => clearInterval(intervalId);
  }, []);

return (
  <div className='Exciting-events'>
    <div className='Exciting-events-header' ><span>Exciting <span style={{color:"#F3FF63",fontStyle:"italic"}} >Events</span></span></div>
    <div className='Exciting-Event-list-container'>
      <div className='Exciting-Event-list'>
          <Link to="/workshops" className='Exciting-Event-card' id='Exciting-Event-card-1'>
            <div className='Exciting-Event-image'>
              <img src={skysprintImgp} alt="Pixel version" />
              <img src={skysprintImg} alt="Normal version" />
              <div className='Exciting-Event-name'>
                <p style={{ color: "#2D48D5" }}>Workshops</p>
                <p style={{ color: "#2D48D5" }}>Workshops</p>
                <p style={{ color: "#2D48D5" }}>Workshops</p>
                <p style={{ color: "#2D48D5" }}>Workshops</p>
                <p style={{ color: "#2D48D5" }}>Workshops</p>
                <p style={{ color: "#2D48D5" }}>Workshops</p>
                <p style={{ color: "#2D48D5" }}>Workshops</p>
                <p style={{ color: "#2D48D5" }}>Workshops</p>
              </div>
            </div>
          </Link>
          <Link to="/competitions" className='Exciting-Event-card' id='Exciting-Event-card-2'>
            <div className='Exciting-Event-image'>
              <img src={valorantImgp} alt="" />
              <img src={valorantImg} alt="" />
              <div className='Exciting-Event-name' >
                <p style={{color:"#2D48D5"}}>Valorant</p>
                <p style={{color:"#2D48D5"}}>Valorant</p>
                <p style={{color:"#2D48D5"}}>Valorant</p>
                <p style={{color:"#2D48D5"}}>Valorant</p>
                <p style={{color:"#2D48D5"}}>Valorant</p>
                <p style={{color:"#2D48D5"}}>Valorant</p>
                <p style={{color:"#2D48D5"}}>Valorant</p> 
                <p style={{color:"#2D48D5"}}>Valorant</p>
              </div>
            </div>
          </Link>
          <Link to="/robotics/robowars" className='Exciting-Event-card' id='Exciting-Event-card-3'>
            <div className='Exciting-Event-image'  >
              <img src={robowarsImgp} alt="" />
              <img src={robowarsImg} alt="" />
              <div className='Exciting-Event-name'>
                <p style={{color:"#2D48D5"}}>Robowars</p>
                <p style={{color:"#2D48D5"}}>Robowars</p>
                <p style={{color:"#2D48D5"}}>Robowars</p>
                <p style={{color:"#2D48D5"}}>Robowars</p>
                <p style={{color:"#2D48D5"}}>Robowars</p>
                <p style={{color:"#2D48D5"}}>Robowars</p>
                <p style={{color:"#2D48D5"}}>Robowars</p>
                <p style={{color:"#2D48D5"}}>Robowars</p>
              </div>
            </div>
            </Link>
            <Link to="/nexus" className='Exciting-Event-card' id='Exciting-Event-card-4'>
              <div className='Exciting-Event-image'>
                <img src={nexusImgp} alt="" />
                <img src={nexusImg} alt="" />
                <div className='Exciting-Event-name'>
                <p style={{color:"#2D48D5"}}>Nexus</p>
                <p style={{color:"#2D48D5"}}>Nexus</p>
                <p style={{color:"#2D48D5"}}>Nexus</p>
                <p style={{color:"#2D48D5"}}>Nexus</p>
                <p style={{color:"#2D48D5"}}>Nexus</p>
                <p style={{color:"#2D48D5"}}>Nexus</p>
                <p style={{color:"#2D48D5"}}>Nexus</p>
                <p style={{color:"#2D48D5"}}>Nexus</p>
              </div>
              </div>
            </Link>
            <Link to="/robotics/aquawars" className='Exciting-Event-card' id='Exciting-Event-card-5'>
              <div className='Exciting-Event-image'>
                <img src={aquawarImgp} alt="" />
                <img src={aquawarImg} alt="" />
                <div className='Exciting-Event-name'>
                  <p style={{color:"#2D48D5"}}>Aquawars</p>
                  <p style={{color:"#2D48D5"}}>Aquawars</p>
                  <p style={{color:"#2D48D5"}}>Aquawars</p>
                  <p style={{color:"#2D48D5"}}>Aquawars</p>
                  <p style={{color:"#2D48D5"}}>Aquawars</p>
                  <p style={{color:"#2D48D5"}}>Aquawars</p>
                  <p style={{color:"#2D48D5"}}>Aquawars</p>
                  <p style={{color:"#2D48D5"}}>Aquawars</p>
                </div>
                </div>
                </Link>
            <Link to="/lectureseries" className='Exciting-Event-card' id='Exciting-Event-card-6'>
            <div className='Exciting-Event-image'>
              <img src={lectureseriesImgp} alt="" />
              <img src={lectureseriesImg} alt="" />
              <div className='Exciting-Event-name'>
                  <p style={{color:"#2D48D5",whiteSpace:"nowrap"}}>Lecture Series</p>
                  <p style={{color:"#2D48D5",whiteSpace:"nowrap"}}>Lecture Series</p>
                  <p style={{color:"#2D48D5",whiteSpace:"nowrap"}}>Lecture Series</p>
                  <p style={{color:"#2D48D5",whiteSpace:"nowrap"}}>Lecture Series</p>
                  <p style={{color:"#2D48D5",whiteSpace:"nowrap"}}>Lecture Series</p>
                  <p style={{color:"#2D48D5",whiteSpace:"nowrap"}}>Lecture Series</p>
                  <p style={{color:"#2D48D5",whiteSpace:"nowrap"}}>Lecture Series</p>
                  <p style={{color:"#2D48D5",whiteSpace:"nowrap"}}>Lecture Series</p>
                </div>
            </div>
            </Link>
           <Link to="/robotics/escalade" className='Exciting-Event-card' id='Exciting-Event-card-7'>
            <div className='Exciting-Event-image'>
              <img src={escaladeImgp} alt="" />
              <img src={escaladeImg} alt="" />
              <div className='Exciting-Event-name'>             
                  <p style={{color:"#2D48D5"}}>Escalade</p>
                  <p style={{color:"#2D48D5"}}>Escalade</p>
                  <p style={{color:"#2D48D5"}}>Escalade</p>
                  <p style={{color:"#2D48D5"}}>Escalade</p>
                  <p style={{color:"#2D48D5"}}>Escalade</p>
                  <p style={{color:"#2D48D5"}}>Escalade</p>
                  <p style={{color:"#2D48D5"}}>Escalade</p>
                  <p style={{color:"#2D48D5"}}>Escalade</p>
                </div>
            </div>
            </Link>
           <Link to="/techexpo" className='Exciting-Event-card' id='Exciting-Event-card-8'>
            <div className='Exciting-Event-image'>
              <img src={techexpoImgp} alt="" />
              <img src={techexpoImg} alt="" />
              <div className='Exciting-Event-name'>           
                  <p style={{color:"#2D48D5"}}>TechExpo</p>
                  <p style={{color:"#2D48D5"}}>TechExpo</p>
                  <p style={{color:"#2D48D5"}}>TechExpo</p>
                  <p style={{color:"#2D48D5"}}>TechExpo</p>
                  <p style={{color:"#2D48D5"}}>TechExpo</p>
                  <p style={{color:"#2D48D5"}}>TechExpo</p>
                  <p style={{color:"#2D48D5"}}>TechExpo</p>
                  <p style={{color:"#2D48D5"}}>TechExpo</p>
                </div>
            </div>
            </Link>
           <Link to="/robotics/tracktitans" className='Exciting-Event-card' id='Exciting-Event-card-9'>
            <div className='Exciting-Event-image'>
              <img src={microImgp} alt="" />
              <img src={microImg} alt="" />
              <div className='Exciting-Event-name'>            
                  <p style={{color:"#2D48D5"}}>TrackTitans</p>
                  <p style={{color:"#2D48D5"}}>TrackTitans</p>
                  <p style={{color:"#2D48D5"}}>TrackTitans</p>
                  <p style={{color:"#2D48D5"}}>TrackTitans</p>
                  <p style={{color:"#2D48D5"}}>TrackTitans</p>
                  <p style={{color:"#2D48D5"}}>TrackTitans</p>
                  <p style={{color:"#2D48D5"}}>TrackTitans</p>
                  <p style={{color:"#2D48D5"}}>TrackTitans</p>
                </div>
            </div>
            </Link>
              {/*repeat
          <div className='Event-card' id='Event-card-6'>
            <div className='Event-image'>
              <img src="https://i.pinimg.com/originals/c4/26/82/c4268244c25de664fae2b19d5ad1611b.jpg" alt="" />
            </div>
           </div>
            <div className='Event-card' id='Event-card-7'>
              <div className='Event-image'>
                <img src="https://images.freeimages.com/images/large-previews/aac/flowing-water-1315406.jpg" alt="" />
              </div>
            </div>
            <div className='Event-card' id='Event-card-8'>
              <div className='Event-image' id="Event-img-8">
                <img src="https://www.pixelstalk.net/wp-content/uploads/2016/04/Nature-Water-Wallpapers-Desktop-download.jpg" alt="" />
              </div>
             </div>
              <div className='Event-card' id='Event-card-9'>
                <div className='Event-image'>
                  <img src="https://wallpapercave.com/wp/wp2596952.jpg" alt="" />
                </div>
              </div>
              <div className='Event-card' id='Event-card-10'>
                <div className='Event-image'>
                  <img src="https://wallpaperaccess.com/full/42272.jpg" alt="" />
                </div>
              </div>*/}
              </div>
              </div>
              
    </div>
  )
}

export default Exciting_events