import LSImg from "../../../assets/lectureseries.png"
import LynVansImg from "../../../assets/lynevans.png"
import LeftButton from '../../../assets/arrowleft.png';
import RightButton from '../../../assets/arrowright.png';
import MarlonWestImg from "../../../assets/marlonwest.png"
import MichealReedImg from "../../../assets/michaelreed.png"
import ArrowLeft from "../../../assets/ArrowLeftEvent.png"
import { contacts , schedule } from './data'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import Tile from "../SpeakersTile/SpeakersTile";
import CursorAnimation from '../../../CursorAnimation'
import Footer from "../../../Footer/Footer";

const LectureSeries = ()=>{

    const [current , setCurrent ] = useState(0)
    const overviewRef = useRef(null)
    const panelDiscRef = useRef(null)
    const pastPanelRef = useRef(null)
    const contactRef = useRef(null)
    
    useEffect(()=>{

        const callback = ()=>{
            [overviewRef.current  , panelDiscRef.current , pastPanelRef.current, contactRef.current].map((ref)=>{
                const rect = ref.getBoundingClientRect()
                if((100*rect.y/window.innerHeight)<30 && (100*rect.y/window.innerHeight)>0) setCurrent(ref.id)
            })
        }

        window.addEventListener('scroll' , callback)
        return ()=>{window.removeEventListener('scroll', callback)}
    },[])
    

    return(
        <>
        <div className="singularevent-outer-wrapper" style={{paddingTop:'12vh'}}>
            <CursorAnimation/>
            <div className="EventNav" style={{display: 'flex', justifyContent: 'space-around' ,width: '100vw',height: '12vh', overflow: 'hidden',paddingTop: '1vw', paddingBottom: '8vw', flexWrap:'wrap', zIndex:'95'}}>

                <div className="event-sidenav"  style={{ color: '#808182' , marginRight: '2%', zIndex:'97', cursor:'pointer'}}>
                    <Link to="/exhibitions"className="event-sidenav-module" style={{fontFamily: 'That_New_Pixel', cursor:'pointer'}}>Exhibitions</Link>
                </div>
                <Link to={`/exhibitions`} style={{alignContent: 'center', alignItems: 'center', zIndex:'95'}}><button className='event-navbutton' style={{alignContent: 'center', alignItems: 'center'}}><img src={LeftButton}  style={{cursor:'pointer'}}/></button></Link>

                <div className="event-nav" style={{marginLeft: 'auto', marginRight: 'auto', display: 'inline', zIndex:'95'}}>
                    <i  style={{fontFamily: 'That_New_Pixel', color: '#2D48D5'}}>Lecture Series</i>
                </div>

                <Link to={`/nexus`} style={{alignContent: 'center', alignItems: 'center', zIndex:'95'}}><button className='event-navbutton' style={{alignContent: 'center', alignItems: 'center'}}><img src={RightButton}   style={{cursor:'pointer'}}/></button></Link>
                <div className="event-sidenav"  style={{fontFamily: 'That_New_Pixel', color: '#808182', marginLeft: '2%', zIndex:'95'}}>
                    <Link to="/nexus" className="event-sidenav-module" style={{fontFamily: 'That_New_Pixel', cursor:'pointer'}}>Nexus</Link>
                </div>

            </div>
            <div className="singularevent-inner-wrapper">
                <div className="singularevent-navbar">
                    <ul style={{display:'flex' , listStyle:'none',backgroundColor:'#F3FF63', justifyContent:'space-evenly'}}>
                        <li onClick={()=>{setCurrent('singularevent-overview')}}>
                            <a style={current==='singularevent-overview'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#'>Overview</a>
                        </li>

                        <li onClick={()=>{setCurrent('singularevent-paneldisc')}}>
                            <a style={current==='singularevent-paneldisc'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#'>Panel Discussion</a>
                        </li>

                        <li onClick={()=>{setCurrent('singularevent-pastpanel')}}>
                            <a style={current==='singularevent-pastpanel'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#registration-scroll-tracker'>Past Panels</a>
                        </li>

                        <li onClick={()=>{setCurrent('singularevent-contact')}}>
                            <a style={current==='singularevent-contact'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#singularevent-contact'>Contact</a>
                        </li>
                      
                    </ul>
                </div>
                <div className="singularevent-content-outer">
                    <div className="singularevent-content-border clip-bg" style={{backgroundColor:'black',padding:'0px 4px 4px 4px'}}>
                        <div className="singularevent-content-inner clip-bg">
                            <div ref={overviewRef} id="singularevent-overview">
                                <div className="singularevent2-content-wrapper">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../assets/EventsHead.png')} alt=" "/>Overview
                                    </div>
                                    <div className="singularevent-content-details">
                                        <div id='events-scroll-tracker'></div> 
                                        This series of keynote lectures brings together a stellar lineup of renowned speakers from various domains. Experts from industries such as technology, finance, entrepreneurship, science, and the arts will share their invaluable insights, experiences, and knowledge with our vibrant community.
                                    </div>    
                                </div>    
                            </div>
                            <div className='singularevent2-img-wrapper'>
                                  <div className='clip-class' style={{padding:'2px',backgroundColor:'black'}}>                               
                                      <img src={LSImg}  alt=""/>
                                  </div>
                            </div>
                            <div ref={panelDiscRef} id="singularevent-paneldisc">
                                <div className="singularevent2-content-wrapper">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../assets/EventsHead.png')} alt=" "/>Panel Discussion
                                    </div>
                                    <div className="singularevent-content-details">
                                        <div id='events-scroll-tracker'></div> 
                                        A platform for in-depth exploration of contemporary issues. Featuring dynamic dialogues between distinguished experts, these sessions foster a deeper understanding through discussion and debate on current topics and trends. Attendees will have the opportunity to engage directly with thought leaders, ask pertinent questions, and gain diverse perspectives on critical issues.
                                    </div>    
                                </div>    
                            </div>
                            {/* <div ref={scheduleRef} id="singularevent-schedule">
                                <div className="singularevent-content-heading">
                                    <img className='singularevent-vector' src={require('../../../assets/EventsHead.png')} alt=" "/>Schedule
                                </div>
                                <div className="singularevent-content-details">
                                    {schedule.map((item)=>{
                                        return(
                                            <div key={item.id} style={{margin:'10px'}}>
                                                <div style={{fontSize:'1.2rem',color:'#F3FF63',fontWeight:'500'}}>{item.date}{item.id<3 && <hr/>}</div>
                                                <ul>
                                                    <li>{item.list[0]}</li>
                                                    <li>{item.list[1]}</li>
                                                    <li>{item.list[2]}</li>
                                                </ul>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div> */}
                            <div ref={pastPanelRef} id="singularevent-pastpanel">
                                <div className="singularevent-content-heading">
                                    <img className='singularevent-vector' src={require('../../../assets/EventsHead.png')} alt=" "/>Past Panels
                                </div>
                                <div className="singularevent-content-details">
                                    <Tile  img={LynVansImg} color="white" Name="Lyn Vans" About="Large Hadron Collider," About2="CERN" />
                                    <Tile  img={MarlonWestImg} color="white" Name="Marlon West" About="Walt Disney" About2="Animation Studios"/>
                                    <Tile  img={MichealReedImg} color="white" Name="Dr. Michel R. Baratte" About="Astronaut, NASA" />
                                    <div id='registration-scroll-tracker'></div>
                                </div>
                            </div>
                            
                            <div ref={contactRef} id="singularevent-contact">
                                <div className="singularevent-content-heading">
                                    <img className='singularevent-vector' src={require('../../../assets/EventsHead.png')} alt=" "/>Contact
                                </div>
                                <div className="singularevent-content-details">
                                    {contacts.map((item)=>{
                                        return(
                                            <div style={{margin:'10px'}}>
                                                <p style={{fontWeight:'500', fontSize:'1.2rem', fontFamily:"FK_Grotesk"}}>{item.name}</p>
                                                <p style={{fontWeight:'100', fontSize:'1rem', fontFamily:"FK_Grotesk"}}>{item.phone}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}
export default LectureSeries