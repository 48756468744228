import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Navbar from "../components/Navbar.js";
import './GhmRegister.css';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";
const CryptoJS = require("crypto-js");

const GHMRegistrationForm = () => {


    const redirectToMeraEvents = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(baseURL + '/ghm/ghmregister', formData);
            const { GHM_ID } = response.data;
            // console.log(GHM_ID);

            // Encrypt
            const ciphertext = CryptoJS.AES.encrypt(GHM_ID, "iitgtechniche").toString().replace(/\//g, '_');
            // console.log(ciphertext);
            const lastFiveLetters = GHM_ID.slice(-5);
            //console.log(lastFiveLetters);
            const redirectUrl = `https://techniche.org/ghm/21confirm/${ciphertext}${lastFiveLetters}`;
            // console.log(redirectUrl);

            // window.location.href = 'https://www.meraevents.com/ticketWidget?eventId=259777&ucode=organizer&wcode=9063CD-9063CD-333333-9063CD-&theme=1&redirectUrl=' + redirectUrl;
            window.location.href = 'https://www.meraevents.com/ticketWidget?eventId=259691&ucode=organizer&wcode=9063CD-9063CD-333333-9063CD-&theme=1&redirectUrl=' + redirectUrl;
            // 'https://www.meraevents.com/event/guwahati-half-marathon-24?ucode=organizer&wcode=9063CD-9063CD-333333-9063CD-&theme=1&redirectUrl=' + redirectUrl;
            // https://www.meraevents.com/ticketWidget?eventId=259777&ucode=organizer&wcode=9063CD-9063CD-333333-9063CD-&theme=1%27+redirectUrl

        } catch (error) {
            let errorMessage = '';
            if (error.response && error.response.status === 409 && formData.CategoryofRace === "21") {
                errorMessage = 'You have already registered for the marathon. Kindly check your email for further information.';
            }
            else {
                errorMessage = 'Registration failed. Please check your credentials.';
            }
            setErrorMessage(errorMessage);
        }
    };

    //    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    const [formData, setFormData] = useState({
        Name: '',
        Age: '',
        Gender: '',
        Organization: '',
        Contact: '',
        Email: '',
        Country: '',
        State: '',
        City: '',
        CategoryofRace: '',
        GeneralChampionship: '',
        Mediaform: '',
        IsPaid: ''
    });


    const [showPayButton, setShowPayButton] = useState(false);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        if (name === "CategoryofRace") {
            setShowPayButton(value === "21");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(baseURL + '/ghm/ghmregister', formData);
            const { message, Name, Email, GHM_ID } = response.data;
            console.log(GHM_ID);
            const ciphertext6 = CryptoJS.AES.encrypt(GHM_ID, "iitgtechniche").toString().replace(/\//g, '_');
            console.log(ciphertext6);
            const lastFiveLetters = GHM_ID.slice(-5);
            //console.log(lastFiveLetters);
            const redirectUrl6 = `https://techniche.org/ghm/6confirm/${ciphertext6}${lastFiveLetters}`;
            window.location.href = redirectUrl6;

        } catch (error) {
            let errorMessage = '';

            // console.log(formData);
            // console.log(formData.CategoryofRace)
            if (error.response && error.response.status === 409 && formData.CategoryofRace === "6") {
                errorMessage = 'You have already registered for the marathon. Kindly check your email for further information.';
            } else {
                errorMessage = 'Registration failed. Please check your credentials.';
            }
            setErrorMessage(errorMessage);
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="ghmregistermain " >
            <Navbar />
            <div className="heading-ghm" style={{ color: "black" }}>REGISTRATIONS ARE CLOSED! SEE YOU AT THE STARTING LINE</div>
            <form onSubmit={handleSubmit} className="form-container-ghm">

                <div className="College1" style={{ color: "black", }}>
                    <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "row" }}><label htmlFor="Name" >Name*</label></div>
                    <input type="text" id='Name' style={{ border: '1px solid', borderColor: '#00000033', }} name="Name" placeholder="Enter your full name" value={formData.Name} onChange={handleChange} required />
                </div>

                <div className="agegender" style={{ color: "black" }}>
                    <div>
                        <label htmlFor="Age">Age*</label>
                        <input type="text" id='Age' style={{ border: '1px solid', borderColor: '#00000033', }} name="Age" placeholder="Enter your age" value={formData.Age} onChange={handleChange} required />
                    </div>

                    <div>
                        <label htmlFor="Gender">Gender*</label>
                        <select name="Gender" id="Genderselect" style={{ border: '1px solid', borderColor: '#00000033', }} value={formData.Gender} onChange={handleChange} required>
                            <option value="" disabled>Select</option>
                            <option value="male" >Male</option>
                            <option value="female">Female</option>
                            <option value="rns">Rather Not Say</option>
                        </select><br />
                    </div>
                </div>

                <div className="College1" style={{ color: "black" }}>
                    <label htmlFor="College">College/Institution/Company</label>
                    <input type="text" id="College" style={{ border: '1px solid', borderColor: '#00000033', color: "black" }} name="Organization" placeholder="Enter your College/Institution/Company" value={formData.Organization} onChange={handleChange} required />
                </div>

                <div className="Contact_details" style={{ color: "black" }}>
                    <div>
                        <label htmlFor="Contact">Contact*</label>
                        <input type="text" id="Contact" name="Contact" style={{ border: '1px solid', borderColor: '#00000033', }} placeholder="Enter your contact details" value={formData.Contact} onChange={handleChange} required />
                    </div>
                    <div style={{ color: "black" }}>
                        <label htmlFor="Email">Email*</label>
                        <input type="text" id="Email" name="Email" style={{ border: '1px solid', borderColor: '#00000033', }} placeholder="Enter your email" value={formData.Email} onChange={handleChange} required />
                    </div>
                </div>

                <div className="Address" style={{ color: "black" }}>
                    <div style={{ color: "black" }}>
                        <label htmlFor="Country">Country*</label>
                        <input type="text" id="Country" style={{ border: '1px solid', borderColor: '#00000033', }} name="Country" placeholder="Country" value={formData.Country} onChange={handleChange} required />
                    </div>

                    <div style={{ color: "black" }}>
                        <label htmlFor="State">State*</label>
                        <input type="text" id="State" style={{ border: '1px solid', borderColor: '#00000033', }} name="State" placeholder="State" value={formData.State} onChange={handleChange} required />
                    </div>

                    <div style={{ color: "black" }}>
                        <label htmlFor="City">City/District*</label>
                        <input type="text" id="City" style={{ border: '1px solid', borderColor: '#00000033', }} name="City" placeholder="City" value={formData.City} onChange={handleChange} required />
                    </div>
                </div>

                <div className="Category1" style={{ color: "black" }}>
                    <label htmlFor="Category">Category*</label>
                    <select name="CategoryofRace" id="Category" style={{ border: '1px solid', borderColor: '#00000033', }} value={formData.CategoryofRace} onChange={handleChange} required>
                        <option value="" disabled>Select</option>
                        <option value="6">6km</option>
                        <option value="21">21km</option>
                    </select>< br />
                </div>

                <div className="Questions" style={{ color: "black" }}>
                    <div className="GeneralChampionship">
                        <label htmlFor="GeneralChampionship">Would You like to participate in General Championship?*</label>
                        <select name="GeneralChampionship" id="GeneralChampionship" style={{ border: '1px solid', borderColor: '#00000033', }} value={formData.GeneralChampionship} onChange={handleChange} required>
                            <option value="" disabled>Select</option>
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                        </select>< br />
                    </div>
                    <div className="accordion" style={{}}>
                        <div className="accordion-header" onClick={toggleAccordion}>
                            <div className={`accordion-icon ${isOpen ? "open" : ""}`}> Click to know more{isOpen ? " -" : " +"}  </div>
                        </div>
                        {isOpen && <div className="accordion-content">The General Championship trophy will be awarded to the college, institution, or group whose total distance covered by its runners is the highest.To find the total distance covered, sum the distances of all runners.<br />For example, if 10 runners cover 21 km each and 15 runners cover 6 km each, the total is 300 km. </div>}
                    </div>

                    <div className="Mediaform" style={{ color: "black" }}>
                        <label htmlFor="Mediaform">How did you get to know about GHM?*</label>
                        <select name="Mediaform" id="Mediaform" style={{ border: '1px solid', borderColor: '#00000033', }} value={formData.Mediaform} onChange={handleChange} required>
                            <option value="" disabled>Select</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Facebook">Facebook</option>
                            <option value="NewsPaper">NewsPaper</option>
                            <option value="NewsChannels">NewsChannels</option>
                            <option value="Posters/Banners">Posters/Banners</option>
                            <option value="Institute">Institute</option>
                            <option value="Friends">Friends</option>
                            <option value="Others">Others</option>
                        </select><br />
                    </div>
                </div>

                {errorMessage && <p className='text-sm text-red-500 pt-2'>{errorMessage}</p>}

                {formData.CategoryofRace === "6" && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><button type="submit" className="btn1" onClick={handleSubmit}>Register</button></div>
                )}
                {formData.CategoryofRace === "21" && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><button type="submit" className="btn1" onClick={redirectToMeraEvents}>Pay now and register</button></div>
                )}

                {/*<div style={{display:'flex',justifyContent:'center',alignItems:'center'}}><button type="submit" className="btn1" style={{backgroundColor:'#05003D', }}>REGISTER</button></div> */}
            </form>


        </div>

    )

}

export default GHMRegistrationForm;