import { useEffect } from "react";

const Marque = () => {
 
  return (
    <div className="marque-container">
      <div className="marque-text">Atmosphere</div>
      <div className="marque-text">Water Bodies</div>
      <div className="marque-text">Women's Sanitation</div>
      <div className="marque-text">Communities</div>
      <div className="marque-text">Guwahati</div>
      <div className="marque-text">Health & Hygiene</div>
      <div className="marque-text">Atmosphere</div>
      <div className="marque-text">Water Bodies</div>
      <div className="marque-text">Women's Sanitation</div>
      <div className="marque-text">Communities</div>
      <div className="marque-text">Guwahati</div>
      <div className="marque-text">Health & Hygiene</div>
    </div>
  );
};

export default Marque;