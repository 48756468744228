import React, { useState } from "react";
import PropTypes from "prop-types";
import "./AllUsers.css";
import { FaSearch } from "react-icons/fa";

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const SearchBar = ({ setResults }) => {
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async (value) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(baseURL+"/caauth/getAllUsers");
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const json = await response.json();
            const results = json.filter((user) => 
                user && user.name && user.name.toLowerCase().includes(value.toLowerCase())
            );
            setResults(results);
        } catch (error) {
            setError("Error fetching data. Please try again later.");
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (value) => {
        setInput(value);
        fetchData(value);
    };

    return (
        <div className="search-bar-container">
            <div className="input-wrapper">
                <FaSearch id="search-icon" />
                <input
                    className="input-search"
                    placeholder="Type CA name..."
                    value={input}
                    onChange={(e) => handleChange(e.target.value)}
                />
            </div>
            {loading && <p>Loading...</p>}
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

SearchBar.propTypes = {
    setResults: PropTypes.func.isRequired,
};

export default SearchBar;
