import React, { useEffect } from "react";
import NavLogo from "../GhmAssets/nav-logo.png";
import logo from "../GhmAssets/techniche-logo.png";
import Hamburger from "../GhmAssets/ham.png";
import facebooklogo from "../GhmAssets/Group 783.png";
import linkedinlogo from "../GhmAssets/Group 785.png";
import twitterlogo from "../GhmAssets/Group 786.png";
import youtubelogo from "../GhmAssets/Group 788.png";
import instalogo from "../GhmAssets/Group 35057.png";
import { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  // const [selected, setSelected] = useState("");
  useEffect(() => {
    const navItems = document.querySelectorAll(".nav-links");

    navItems.forEach((item) => {
      item.addEventListener("click", function () {
        navItems.forEach((navItem) => navItem.classList.remove("nav-active"));
        this.classList.add("nav-active");
      });
    });
  }, []);
  return (
    <>
      <nav id="nav">
        <Link to='/'><img src={NavLogo} alt="logo" className="navghm" /></Link>
        <div className="nav-links-container">
          <a href="https://forms.gle/SZ4fXsvKikPUCZeD6">
            <div
              className={
                "nav-links"
              }
            // onClick={() => setSelected("merch")}
            >
              MERCHANDISE
            </div>
          </a>
          <Link to="/ghm/about">
            <div
              className={
                "nav-links"
              }
            // onClick={() => setSelected("about")}
            >
              ABOUT GHM
            </div>
          </Link>
          <Link to="/ghm/eventdetails">
            <div
              className={
                "nav-links"
              }
            // onClick={() => setSelected("event")}
            >
              EVENT DETAILS
            </div>
          </Link>
          <Link to="/ghm/register">
            <button className="register-btn">REGISTER</button>
          </Link>
        </div>
        <div className="hamburger" onClick={() => setOpen(!open)}>
          <img src={Hamburger} alt="ham"></img>
        </div>
      </nav>
      <div className={open ? "hamburger-ghm-menu" : "ham-menu"}>
        <div className="top">
          <img src={logo} alt="" />
          <p className="close" onClick={() => setOpen(!open)}>
            ×
          </p>
        </div>
        <div className="ham-links">
          <a href="https://forms.gle/SZ4fXsvKikPUCZeD6">
            <p>MERCHANDISE</p>
          </a>
          <Link to="/ghm/about">
            <p>ABOUT GHM</p>
          </Link>
          <Link to="/ghm/eventdetails">
            <p>EVENT DETAILS</p>
          </Link>
        </div>
        <div className="vector ham-vector">
          <a
            href="https://www.instagram.com/techniche_iitguwahati?igsh=YTh3NWRmbmpwbTRu"
            className="instalogosghm"
          >
            <img src={instalogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a
            href="https://www.facebook.com/techniche.iitguwahati/"
            className="facebooklogosghm"
          >
            <img src={facebooklogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a
            href="https://in.linkedin.com/company/techniche-iitg"
            className="linkedinlogosghm"
          >
            <img src={linkedinlogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a href="" className="twitterlogosghm">
            <img src={twitterlogo} style={{ width: "100%", height: "100%" }} />
          </a>
          <a
            href="https://www.youtube.com/user/technicheiitg"
            className="youtubelogosghm"
          >
            {" "}
            <img src={youtubelogo} style={{ width: "100%", height: "100%" }} />
          </a>
        </div>
        <Link to="/ghm/register">
          <button className="ham-button">REGISTER NOW</button>
        </Link>
      </div>
    </>
  );
};

export default Navbar;