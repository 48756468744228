import React from 'react';
import './merch.css';
import { Link } from 'react-router-dom';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import WhiteTshirtURL from './Model/White-Tshirt.glb';
import BlackTshirtURL from './Model/Black-Tshirt.glb';
import Marquee from '../EventsModule/Marquee/Marquee.js'
import rotate from '../../assets/rotate.png'
import CursorAnimation from '../../CursorAnimation.js';
import Footer from "../../Footer/Footer"

const Model = ({ path, scale, position }) => {
  const { scene } = useGLTF(path);
  return <primitive object={scene} scale={scale} position={position} />;
};

const Merch = () => {
  const GetyourexclusivemerchnowItems = ['Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!','Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!','Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!','Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!','Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!','Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!','Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!','Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!', 'Get your exclusive merch now!'];
  return (
    <div className='m_p'>
      <CursorAnimation/>
      <div className="merchpage1">
        <div className="canvas1">
          <Canvas className='canvas'>
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            <Model path={BlackTshirtURL} scale={[1.2, 1.2, 1]} position={[-0, -2.5, 0]} />
            <OrbitControls 
              enableZoom={false} 
              autoRotate={true} 
              autoRotateSpeed={2} 
              enablePan={false} 
              minPolarAngle={Math.PI / 2} 
              maxPolarAngle={Math.PI / 2} 
            />
          </Canvas>
          <img src={rotate}/>
        </div>
        <div className="text-container">
          <div className="selling-fast">Selling Fast!</div>
          <div className="title">
            <div className='merch-serial-number'>01//</div>
            <div className='merch-name'>Birds aren't Real</div>
          </div>
          <div className="description">Unleash your inner conspiracy theorist with our 'Birds Aren't Real' black cotton t-shirt.</div>
          <div className="price-container-merch">
            <span className="cutted-price">₹500</span>
            <span className="price">₹450</span>
          </div>
          <div className='merch-btn-container' style={{paddingTop:'2vh'}}>
            <Link to="https://docs.google.com/forms/d/e/1FAIpQLScil8uSxYqN6WJ8tkeMbKTZ5lEcxPmoBHF-YJgjk9tamgG5nQ/viewform" style={{height: '12vh'}}>
                <div className='merch-btn-wrapper' style={{ backgroundColor: 'black', width: 'max-content',padding:'0.05vh', display:'flex', justifyContent:'center', alignItems:'center', }} >
                  <span className='merch-btn'>Buy Now</span>
                </div>
            </Link>
          </div>
        </div>
      </div>
      <div class="merch-banner-wrapper">
          <Marquee bgColor="#F3FF63" letterColor="#2D48D5" text={GetyourexclusivemerchnowItems}/>
      </div>

      <div className="merchpage2" >
        <div className="text-container" style={{marginLeft:'5vw'}}>
          <div className="selling-fast">Selling Fast!</div>
          <div className="title">
            <div className='merch-serial-number'>02//</div>
            <div className='merch-name'>Quack the code</div>
          </div>
          <div className="description">A playful twist on tech with our 'Quack the Code' design.</div>
          <div className="price-container-merch">
            <span className="cutted-price">₹500</span>
            <span className="price">₹450</span>
          </div>
          <div className='merch-btn-container' style={{paddingTop:'2vh'}}>
            <Link to="https://docs.google.com/forms/d/1TtmJfS0B6h_BWhwXqU4i11R8hbINkaaq--BRKf530m8/edit?ts=66b8cb6e" style={{height: '12vh'}}>
                <div className='merch-btn-wrapper' style={{ backgroundColor: 'black', width: 'max-content',padding:'0.05vh', display:'flex', justifyContent:'center', alignItems:'center', }} >
                  <span className='merch-btn'>Buy Now</span>
                </div>
            </Link>
          </div>
        </div>
        <div className="canvas1">
          <Canvas className='canvas'>
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            <Model path={WhiteTshirtURL} scale={[1.2, 1.2, 1]} position={[-0, -2.5, 0]} />
            <OrbitControls 
              enableZoom={false} 
              autoRotate={true} 
              autoRotateSpeed={2} 
              enablePan={false} 
              minPolarAngle={Math.PI / 2} 
              maxPolarAngle={Math.PI / 2} 
            />
          </Canvas>
          <img src={rotate}/>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Merch;


