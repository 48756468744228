import React from 'react';
import ScrollingText from './ScrollingText';
import EventList from './EventList';
import Footer from "../../Footer/Footer"
import CursorAnimation from '../../CursorAnimation';

const NewEventPage = () => {
  return (
    <div className='events-consolidated-wrapper' style={{zIndex:'120'}}>
      <CursorAnimation />
      <ScrollingText/>
      <EventList/>
      <div className='last'><Footer/></div>
    </div>
  );
};

export default NewEventPage;
